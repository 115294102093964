import React,{useEffect,useRef} from 'react'
import './Content.css'
import gsap from 'gsap'

// images
import mobiles from '../Assets/2mobiles.webp'
import bubble from '../Assets/bubbles.svg'


const Content = () => {
  const circleRef = useRef(null);
    useEffect(() => {
      gsap.to("#mobile", {
        y: 70,
        duration: 1,
        scrollTrigger: {
          trigger: "#mobile",
          start: "top center",
          end: "bottom 80px",
          scrub: 6,
        }
      });
    }, []);

  return (
    <div className='content'>
      <div className='container contentt'>
        <div className='contentText'>
              <h2 className='baslik6'>Profesyonel Kurumsal <strong style={{color:'#eb2f5b'}}>Web Sitesi</strong> Tasarımı ve <strong style={{color:'#eb2f5b'}}>SEO</strong> Hizmetleri</h2>
              <p className='text2'>Plaza web tasarım ajansı olarak şirketinizin ihtiyaçlarını belirleyerek sitenizi size en uygun
                  şekilde seo uyumlu olarak tasarlar,daha sonra ihtiyacınız olduğunda sitenizin güncellemeleri ,
                  bakımı ve iyileştirilmesi için çalışırız.
              </p>
              <div className="orn">

              <a className='contentBtn text' href="/hizmetlerimiz">Detaylı İncele <ion-icon name="arrow-forward-outline"></ion-icon></a>
              </div>
          </div>
          <div className="contentImg">
              <img src={mobiles} ref={circleRef} id='mobile' height='auto' max-width='400' style={{zIndex:'2',position:'absolute',top:20}}alt="illustration" />
              <img src={bubble} width='200px' height='200px' className='bubbles' alt="illustration" />
          </div>
      </div>
    </div>
  )
}

export default Content