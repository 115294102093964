import React,{useLayoutEffect} from 'react';
import Slider from "react-slick";
import './Cards.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {HiOutlineRocketLaunch} from 'react-icons/hi2'
import {MdOutlineDesignServices} from 'react-icons/md'
import {BiCodeCurly} from 'react-icons/bi'
import {LiaShoppingBasketSolid} from 'react-icons/lia'


//gsap
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


function Cards() {

   // yazı rengi değiştirme
   useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);
    mm.add('(min-width: 1100px)', () => {
      const textElements = gsap.utils.toArray('.baslik4');

      textElements.forEach((text) => {
        gsap.to(text, {
          backgroundSize: '70%',
          ease: 'none',
          scrollTrigger: {
            trigger: text,
            start: 'top 50%',
            end: 'bottom',
            // markers:true,
            scrub: true,
          },
        });
      });
    });
    return () => mm.revert(); // <-- this goes outside the matchMedia.add() function
  }, []);
    // yazı rengi değiştirme
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    initialSlide: 0,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    return (
      <div className='container cards' id='cards'>
        <h2 className='baslik6'>Neler Yapıyoruz <strong style={{color:'#eb2f5b'}}>?</strong></h2>
        <Slider {...settings}>
          <div className='card'>
            <div className="inner">
              <div className='cardIcon'>
                <div className='front'><MdOutlineDesignServices className='icon'/></div>
                <div className='back'><MdOutlineDesignServices className='icon'/></div>
              </div>
              <h3 className='baslik3'>Web Tasarım</h3>
              <div className="text">
              İlerleyen teknolojiyi ve kullanıcı ihtiyaçlarını göz önünde bulundurarak, işlevsel ve estetik web tasarımlarıyla markanızı dijital dünyada öne çıkarıyoruz.</div>
            </div>
            
          </div>
          <div className='card'>
          <div className="inner">
              <div className='cardIcon'>

                <div className='front'><LiaShoppingBasketSolid className='icon'/></div>
                <div className='back'><LiaShoppingBasketSolid className='icon'/></div>
                
              </div>
              <h3 className='baslik3'>E-Ticaret</h3>
              <div className="text">Sağlam altyapımız sayesinde geliştirdiğimiz E-ticaret siteleri, müşterilerinizin taleplerine karşılık veriyor ve ürünlerinizin kolayca satın alınmasını sağlıyoruz.</div>
            </div>
            
          </div>
          <div className='card'>
          <div className="inner">
              <div className='cardIcon'>

                <div className='front'><BiCodeCurly className='icon'/></div>
                <div className='back'><BiCodeCurly className='icon'/></div>
              </div>
              <h3 className='baslik3'>Web Yazılım</h3>
              <div className="text">Web tabanlı çözümlerle, site yönetim panellerinden mobil uygulamalara, CRM sistemlerinden iş/teklif izleme programlarına kadar geniş bir yelpazede özelleştirilmiş yazılım hizmetleri sunuyoruz.</div>
            </div>
            
            
          </div>
          <div className='card'>
          <div className="inner">
              <div className='cardIcon'>

                <div className='front'><HiOutlineRocketLaunch className='icon'/></div>
                <div className='back'><HiOutlineRocketLaunch className='icon'/></div>
              </div>
              <h3 className='baslik3'>SEO</h3>
              <div className="text">
                  Ziyaretçilerinizi müşteriye dönüştürmek ve web sitenizi sektörün öncüsü haline getirmek adına, gereken tüm optimizasyonları doğru bir şekilde uyguluyoruz.</div>
            </div>
            
          </div>
          <div className='card'>
          <div className="inner">
              <div className='cardIcon'>

                <div className='front'> <svg className='iconn' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 32 32" id="mobile-code"><path d="M26,4c0,-1.656 -1.344,-3 -3,-3c-0,0 -14,0 -14,0c-1.656,0 -3,1.344 -3,3l0,24c0,1.656 1.344,3 3,3c0,0 14,0 14,0c1.656,0 3,-1.344 3,-3l0,-24Zm-2,23l0,1c0,0.552 -0.448,1 -1,1l-14,0c-0.552,0 -1,-0.448 -1,-1l0,-1l16,0Zm0,-2l-16,0l0,-21c0,-0.552 0.448,-1 1,-1l14,0c0.552,0 1,0.448 1,1l0,21Zm-5.832,-11.445l1.63,2.445c0,0 -1.63,2.445 -1.63,2.445c-0.306,0.46 -0.182,1.081 0.277,1.387c0.46,0.306 1.081,0.182 1.387,-0.277l2,-3c0.224,-0.336 0.224,-0.774 0,-1.11l-2,-3c-0.306,-0.459 -0.927,-0.583 -1.387,-0.277c-0.459,0.306 -0.583,0.927 -0.277,1.387Zm-6,-1.11l-2,3c-0.224,0.336 -0.224,0.774 -0,1.11l2,3c0.306,0.459 0.927,0.583 1.387,0.277c0.459,-0.306 0.583,-0.927 0.277,-1.387l-1.63,-2.445c-0,-0 1.63,-2.445 1.63,-2.445c0.306,-0.46 0.182,-1.081 -0.277,-1.387c-0.46,-0.306 -1.081,-0.182 -1.387,0.277Zm3.438,2.108l-1,2c-0.247,0.493 -0.047,1.095 0.447,1.341c0.493,0.247 1.095,0.047 1.341,-0.447l1,-2c0.247,-0.493 0.047,-1.095 -0.447,-1.341c-0.493,-0.247 -1.095,-0.047 -1.341,0.447Zm-0.606,-8.553l2,-0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-2,-0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1Z"></path></svg>  </div>
                <div className='back'><svg className='iconn' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 32 32" id="mobile-code"><path d="M26,4c0,-1.656 -1.344,-3 -3,-3c-0,0 -14,0 -14,0c-1.656,0 -3,1.344 -3,3l0,24c0,1.656 1.344,3 3,3c0,0 14,0 14,0c1.656,0 3,-1.344 3,-3l0,-24Zm-2,23l0,1c0,0.552 -0.448,1 -1,1l-14,0c-0.552,0 -1,-0.448 -1,-1l0,-1l16,0Zm0,-2l-16,0l0,-21c0,-0.552 0.448,-1 1,-1l14,0c0.552,0 1,0.448 1,1l0,21Zm-5.832,-11.445l1.63,2.445c0,0 -1.63,2.445 -1.63,2.445c-0.306,0.46 -0.182,1.081 0.277,1.387c0.46,0.306 1.081,0.182 1.387,-0.277l2,-3c0.224,-0.336 0.224,-0.774 0,-1.11l-2,-3c-0.306,-0.459 -0.927,-0.583 -1.387,-0.277c-0.459,0.306 -0.583,0.927 -0.277,1.387Zm-6,-1.11l-2,3c-0.224,0.336 -0.224,0.774 -0,1.11l2,3c0.306,0.459 0.927,0.583 1.387,0.277c0.459,-0.306 0.583,-0.927 0.277,-1.387l-1.63,-2.445c-0,-0 1.63,-2.445 1.63,-2.445c0.306,-0.46 0.182,-1.081 -0.277,-1.387c-0.46,-0.306 -1.081,-0.182 -1.387,0.277Zm3.438,2.108l-1,2c-0.247,0.493 -0.047,1.095 0.447,1.341c0.493,0.247 1.095,0.047 1.341,-0.447l1,-2c0.247,-0.493 0.047,-1.095 -0.447,-1.341c-0.493,-0.247 -1.095,-0.047 -1.341,0.447Zm-0.606,-8.553l2,-0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-2,-0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1Z"></path></svg></div>
              </div>
              <h3 className='baslik3'>Mobil Uygulama</h3>
              <div className="text">Kullanıcı dostu ve işlevsel mobil uygulamalar geliştiriyoruz, işinizin dijital dünyadaki varlığını güçlendirmek için size özel çözümler sunuyoruz.</div>
            </div>
            
          </div>
         
        </Slider>
      </div>
  );
}


export default Cards