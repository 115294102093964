import React,{useState} from 'react'
import { NavLink,Outlet } from 'react-router-dom'
import './Navbar.css';

// button
import MenuButton from './MenuButton';

// icon

import {BsLinkedin ,BsInstagram} from 'react-icons/bs'

const Navbar = () => {
  const [navOpen,setNavOpen] = useState(false)


  const body = document.body;
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 200) {
      body.classList.remove("scroll-up");
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains("scroll-down")
    ) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }
    lastScroll = currentScroll;
  });

  return (
    <div>
      <header>

        {/* bilgisayar */}

        <nav className='menuNav'>
              <div className='menuLists' >
                  <a className='menuItem text' href='/'>Anasayfa</a>
                  <a href='/hizmetlerimiz' className='menuItem text' >Hizmetlerimiz</a>
                  <a href='/anasayfa' className='menuItem logo' ><img src="../img/plazayazilim-logob.svg" width='140px' height='60px' alt="plaza yazılım logo" /></a>
                  
                  <a href="/anasayfa#jobs" className='menuItem text'>Projelerimiz</a>
                  <a href='/iletisim' className='menuItem text' >İletişim</a>
                  <MenuButton/>
              </div>
          </nav>

          {/* mobil */}
          
          <div className='mobile'>
              <div className="nav">
                <div className="nav-container">
                  <div className="navbar">
                    <div className="logo"><a href="/anasayfa"><img src="img/plazayazilim-logob.svg" width='150px' height='90px' alt="plaza yazılım logo" /></a></div>
                    <div className="menu-toggle" onClick={()=>setNavOpen(!navOpen)}>
                      <MenuButton />
                    </div>
                  </div>
                  <div className="nav-overlay" style={{
                    top:navOpen?'0':'-120%',
                    transitionDelay:navOpen ? '0s' : '0s',
                  }}>
                    <ul className='nav-links'>
                      <li className='nav-item'>
                        <a className='baslikText'
                        href='/' 
                        onClick={()=> setNavOpen(!navOpen)}
                        style={{
                          top:navOpen ? '0': '120px',
                          transitionDelay: navOpen ? '0.7s' :'0s',
                        }}
                        >Anasayfa</a>
                        <div className="nav-item-wrapper"></div>
                      </li>
                      <li className='nav-item'>
                        <a className='baslikText'
                        href='/hizmetlerimiz' 
                        onClick={()=> setNavOpen(!navOpen)}
                        style={{
                          top:navOpen ? '0': '120px',
                          transitionDelay: navOpen ? '0.8s' :'0s',
                        }}
                        >Hizmetlerimiz</a>
                        <div className="nav-item-wrapper"></div>
                      </li>
                      <li className='nav-item'>
                        <a className='baslikText'
                        href='/anasayfa#jobs'
                        onClick={()=> setNavOpen(!navOpen)}
                        style={{
                          top:navOpen ? '0': '120px',
                          transitionDelay: navOpen ? '0.9s' :'0s',
                        }}
                        >Projelerimiz</a>
                        <div className="nav-item-wrapper"></div>
                      </li>
                      <li className='nav-item'>
                        <a className='baslikText'
                        href='/iletisim' 
                        onClick={()=> setNavOpen(!navOpen)}
                        style={{
                          top:navOpen ? '0': '120px',
                          transitionDelay: navOpen ? '1s' :'0s',
                        }}
                        >İletişim</a>
                        <div className="nav-item-wrapper"></div>
                      </li>
                    </ul>
                    <div className="nav-footer">
                      <div className="location"
                       style={{
                        bottom:navOpen ? '0': '-20px',
                        opacity:navOpen ?'1':'0',
                        transitionDelay: navOpen ? '1.2s' :'0s',
                      }}>
                        <span>Tekirdağ,TR</span>
                      </div>
                      <div className="nav-social-media">
                        <ul>
                          <li>
                            <a href="https://www.instagram.com/plazayazilimcom/"
                               style={{
                                bottom:navOpen ? '0': '-20px',
                                opacity:navOpen ?'1':'0',
                                transitionDelay: navOpen ? '1.3s' :'0s',
                                
                              }}
                              aria-label='İnstagrama git'
                            ><BsInstagram/></a>
                          </li>
                          <li>
                            <a href="#"
                               style={{
                                bottom:navOpen ? '0': '-20px',
                                opacity:navOpen ?'1':'0',
                                transitionDelay: navOpen ? '1.4s' :'0s',
                              }}
                              aria-label='Linkedine git'
                            > <BsLinkedin/> </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </header>
        <main>
        <Outlet />
      </main>
    </div>
  )
}

export default Navbar