import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// import components
import Home from './components/Home/Home';
import Services from "./components/Kurumsal/Services/Services";
import WebTasarim from "./components/Kurumsal/WebTasarim/WebTasarim";
import WebYazilim from "./components/Kurumsal/WebTasarim/WebYazilim";
import DijitalTasarim from "./components/Kurumsal/WebTasarim/DijitalTasarim";
import ETicaret from "./components/Kurumsal/WebTasarim/Eticaret";
import MobilUygulama from "./components/Kurumsal/WebTasarim/MobilUygulama";
import DijitalPazarlama from "./components/Kurumsal/WebTasarim/DijitalPazarlama";

//import Layouts
import Navbar from './components/Layouts/Navbar';
import Footer from "./components/Layouts/Footer";
import Contact from "./components/Contact/Contact";
import TeklifAl from "./components/Offer/TeklifAl";
import WpButton from "./components/WpButton/WpButton";
import PhoneButton from "./components/WpButton/PhoneButton";


const router = createBrowserRouter([
  {
    path:'/',
    element:<Navbar />,
    children:[
    {index:true,element: (<Home />),},
    {path: "/anasayfa",element: (<Home />),},
    {path: "/hizmetlerimiz",element: (<Services />),},
    {path: "/web-tasarim",element: (<WebTasarim />),},
    {path: "/web-yazilim",element: (<WebYazilim />),},
    {path: "/dijital-tasarim",element: (<DijitalTasarim />),},
    {path: "/e-ticaret",element: (<ETicaret />),},
    {path: "/mobil-uygulama",element: (<MobilUygulama />),},
    {path: "/dijital-pazarlama",element: (<DijitalPazarlama />),},
    {path: "/iletisim",element: (<Contact />),},
    {path: "/teklif-al",element: (<TeklifAl />),},
    // {path: "/referanslar",element: (<Reference />),},
    
  ]
  }
])

function App() {
  return (
    <div>
        <RouterProvider router={router} />
        <PhoneButton/>
        <WpButton/>
        <Footer/>
    </div>
  );
}

export default App;