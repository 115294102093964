import React,{useState} from 'react'
import './Question.css'

import {FaAngleRight} from 'react-icons/fa'

const Question = ()=>{
    const [selected ,setSelected] = useState(null);
    // const toggle =(i)=>{
    //     if(selected === i){

    //         return setSelected(null)
    //     }
    //     setSelected(i)
    // }
    const toggleAccordion = (index) => {
        if (selected === index) {
          setSelected(null);
        } else {
          setSelected(index);
        }
      };
    
      const accordionData = [
        {
          id:'1',
          title: 'Web Tasarım Ajansı Nedir?',
          header:'Web Tasarım Ajansı',
          content:'Dünya genelinde internet kullanımı hızla artıyor ve işletmeler ile markalar için etkili bir iletişim aracı haline gelen profesyonel web siteleri büyük bir öneme sahip. Web siteleri, işletmelerin dijital dünyada varlık göstermelerini, ürün ve hizmetlerini tanıtmalarını ve potansiyel müşterilere ulaşmalarını sağlayan bir platform olarak işlev görüyor. Web sitelerinin profesyonelce tasarlanması, yönetilmesi ve arama motorlarında optimize edilmesi gerekmektedir. İşte bu noktada devreye web tasarım ajansları girer.',
          header2:'Web Tasarım Ajanslarının Rolü',  
          text1:'Web tasarım ajansları, işletmelerin ve markaların ihtiyaçlarına uygun web siteleri oluşturmak, yönetmek ve geliştirmek konusunda uzmanlaşmış profesyonel ekiplerdir. İşte web tasarım ajanslarının sunduğu ana hizmetler:',
          li1:`Web Sitesi Tasarımı: İşletmenizin kimliğini yansıtan profesyonel web siteleri tasarlarlar. Web sitenizin görsel ve kullanıcı deneyimi açısından etkileyici olması, müşterilerin ilgisini çekmesi için kritik öneme sahiptir.`,
          li2:'Web Yazılım: Web sitelerinin arkasındaki teknik altyapıyı oluştururlar. Güçlü bir web yazılımı, sitenizin performansını ve işlevselliğini artırır.',
          li3:'Arama Motoru Optimizasyonu (SEO): Web sitenizin arama motorlarında üst sıralarda görünmesini sağlayarak organik trafik çekmenize yardımcı olurlar. Anahtar kelime araştırması, içerik optimizasyonu ve backlink yönetimi gibi SEO stratejilerini uygularlar.',
          li4:'Dijital Pazarlama: Web sitenizin ürün ve hizmetlerini tanıtmak için dijital pazarlama stratejileri geliştirirler. Google Ads, sosyal medya reklamları, e-posta pazarlama ve içerik pazarlama gibi kanalları kullanarak müşteri kitlenize ulaşmanızı sağlarlar.',
          li5:'Sosyal Medya Yönetimi: Sosyal medya platformlarında markanızın etkileşimini artırmak için stratejiler geliştirirler. Sosyal medya hesaplarınızı yönetir ve içerik üretirler.',
          li6:'E-Posta Pazarlama: Elektronik posta ile müşterilerinize özel teklifler, güncellemeler ve içerikler göndermek için e-posta pazarlama stratejileri oluştururlar.',
          li7:'Kurumsal bir web tasarım ajansı tercih edilmelidir.',
          li8:'Programlama ve web yazılım konusundaki uzmanlık göz önünde bulundurulmalıdır.',
          li9:'Kreatif çözümler sunabilen bir ajans seçilmelidir.',
          li10:'Teknik destek ve sürdürülebilirlik sağlayabilen bir ajans tercih edilmelidir.',
          li11:'Fiyat performans dengesi değerlendirilmelidir.',
          header3:'Web Tasarım Ajansı Seçerken Nelere Dikkat Edilmelidir?',
          text2:'Sonuç olarak, web tasarım ajansları işletmelerin dijital varlıklarını güçlendirmelerine yardımcı olur. İşletmeler, profesyonel web tasarım ajanslarıyla çalışarak markalarını dijital dünyada başarıyla temsil edebilirler. Adspertise yazılım olarak deneyimli ekibimiz ve geniş hizmet yelpazemizle sizin için özelleştirilmiş web tasarım çözümleri sunmaya hazırız. İşletmenizin ihtiyaçlarına uygun bir web sitesi oluşturmak ve dijital dünyada daha fazla başarı elde etmek için bize başvurun!',
        },
        {
          id:'2',
          title: 'Kurumsal Web Tasarım',
          header:'Web Tasarım Ajansı',
          content: 'Kurumsal web tasarım, işletmenizin özgün gereksinimlerine uygun olarak profesyonel bir şekilde tasarlanan web sitenizi kapsar. Adspertise Yazılım olarak, işletmenizin ve markanızın tanıtımını en son yazılım ve grafik teknolojileri ile yapmanıza olanak tanıyoruz. İnternet üzerinde kurumsal kimliğinizi ne kadar iyi yansıtırsanız, rakiplerinizin önüne geçme olasılığınız o kadar artar. Unutmayın, işletmenizin büyüklüğü veya hangi sektörde hizmet verdiği fark etmeksizin, kurumsal bir web sitesi artık bir lüks değil, bir gerekliliktir!                ',
          header2:'Kurumsal Web Tasarım Nedir?',
          text1:'Kurumsal web tasarım, işletmenizin kurumsal yapısını en etkili şekilde yansıtan web tasarım çalışmalarını içerir. Ekibimiz, işletmenizin kurumsal kimliğine uygun bir web sitesi tasarlar ve işletmenizi geniş bir kitleye tanıtmanıza yardımcı olur. Kurumsal web tasarım hizmeti, firmanızın imajını her zaman güçlendirir. Ayrıca, sektörünüzün dinamiklerini dikkate alarak kaliteli ve yaratıcı bir web tasarım çalışması planlarız.',
          text3:'Kurumsal web tasarım hizmetlerinde, işletmenizin sektörü, logosu, ürün ve hizmetleri, hedef kitlesi, misyon ve vizyonu gibi birçok faktörü göz önünde bulundurarak projelerimizi oluştururuz. Rakip analizi ve hedef kitle analizi sonuçlarına dayanarak web tasarım çalışmalarımızı şekillendiririz.',
          header3:'Kurumsal Web Tasarımın Önemi',
          text4:'İnternet kullanımının artmasıyla birlikte, küçük işletmeler bile kolayca kurumsallaşabilir. İşletmenizin imajını yansıtmak ve güçlendirmek için bir kurumsal kimliğe sahip olmanız gereklidir. Potansiyel müşterileriniz, ürün ve hizmetlerinizi satın almadan önce internet üzerinde araştırma yapar. Web siteniz, sizin dijital dünyadaki mağazanızdır. Bu nedenle, kurumsal bir web tasarım hizmeti ile web sitenize özel önem vermelisiniz.',
          text5:'Web siteniz, potansiyel müşterilerinizin gözünde güçlü bir ilk izlenim yaratır. Web sitenizin kurumsal kimliğini yansıtan kaliteli ve özgün bir tasarıma ihtiyacınız vardır. Tüm bunları sağlamak için kurumsal web tasarım hizmeti almalısınız.',
          text6:'Profesyonel web tasarım hakkında daha fazla bilgi için bize hemen ulaşın ve görüşelim.',
        },
        {
          id:'3',
          title: 'Web Tasarım Önemli mi?',
          header:'Web Sitesi Tasarımı',
          content: 'Web sitesi tasarımı, internet ortamında görüntülenen web sitelerinin oluşturulmasını ifade eden bir kavramdır. İşletmeler dijital ortamdaki görünürlüğünü güçlendirmek adına kurumsal bir web sitesine ihtiyaç duyarlar. Web sitesi sayesinde ürün ve hizmetlerinizi daha etkili şekilde pazarlayabilirsiniz.',
          text1:'Profesyonel bir bakış açısıyla hazırlanan web siteleri potansiyel müşterilerinizle buluşmanın en etkili, hızlı ve en düşük maliyetli yoludur. Bir web sitesi markaları internet ortamındaki ofisi ve showroomudur.',
          text2:'Web sitesi tasarımı basit bir işlem değildir. Özellikle işletmeler için müşteri kitlesinin aradığı ürünü kolay bulacağı, web sitesinde güzel vakit geçireceği bir tasarım şarttır. Kumsal Ajans olarak her zaman sade, şık ve etkili bir web sitesi tasarlıyoruz.',
          text5:'Web siteniz ziyaretçiler tarafından kolay algılanan yapıda olduğunda istediğiniz mesajı müşteri kitlenize en etkili şekilde ulaştırabiliyorsunuz. Bir web sitesi tasarımında farklı disiplinler, beceriler ve özellikler bir araya geliyor. Web sitesi tasarımı yaparken müşterilerimize web tasarım yanında grafik tasarım, ara yüz tasarımı, içerik oluşturma, ihtiyaca özel kodlama, arama motoru optimizasyonu gibi hizmetleri de sunuyoruz.',
          header4:'Web Sitesi Tasarımı Neden Önemlidir?',
          text7:'Bir işletme küçük ölçekli dahi olsa web sitesine ihtiyaç duyuyor. Sektörünüz ne olursa olsun müşteri kitlenizle buluşabilmek ve kurumsallığınızı tamamlayabilmek için web sitesi tasarımı hizmeti almalısınız.',
          text8:'İşletmeler için web sitesinin gerekliliği şöyle açıklanabilir;',
          li12:'a',
          li13:'a',
          li14:'a',
          li15:'a',
          text9:'Yukarıdaki verilerin tamamı işletmelerin profesyonel web tasarım hizmetine neden ihtiyaç duyduklarını açıklıyor. Potansiyel müşterileriniz için bir internet sitesine sahip olmanız yeterli olmuyor. İnternet sitenizin güvenilir, kaliteli ve nitelikli olması da gerekiyor. Bunun için de ziyaretçi kitlenizi iyi tanımalı ve onların taleplerine cevap verebilmelisiniz.',
          text10:'Plaza Yazılım profesyonel web tasarım , yazılım hizmeti ile tanışmaya ne dersiniz ? '
        },
        {
          id:'4',
          title: 'Web Tasarım Nasıl Olmalıdır?',
          header: 'Profesyonel Web Tasarım ',
          content: 'İşletmeler internette varlık göstermek için web sitesine sahip olmalıdır. Bunun için de web tasarım hizmeti almanız gerekiyor. Bu hizmeti alabileceğiniz çok sayıda ajans bulsanız da profesyonel web tasarım hizmeti aldığınızdan emin olmalısınız. İnternette vakit geçiren kullanıcılar aradıkları bilgileri, görselleri ve içerikleri kolayca bulmak istiyor. Bu yüzden karmaşık ve kullanıcı deneyimi zorlu olan internet sitelerinden hemen çıkma oranı çok yüksek oluyor. Web tasarımda sadelik kullanıcıların markanıza olan güvenini arttıracaktır.',
          text1:'Hızlı açılan, mobil cihazlardan erişilebilen, kullanıcılara aradıklarını kısa yoldan sunabilen web siteleri her zaman daha çok tercih edilir. Profesyonel web tasarım ürün ve hizmetlerinizi potansiyel müşterilerinize en etkili biçimde ulaştırmanıza odaklanıyor.',
          header3:'Profesyonel Web Tasarım Neden Önemlidir?',
          text5:'İşletme sahipleri genellikle “Bir web sitem olsun yeterli!” mantığıyla hareket ediyor. Bu yüzden web tasarım hizmetinde profesyonellik ve kaliteye önem verilmiyor.',
          text7:'İşte profesyonel web tasarım hizmetini önemli hale getiren bazı veriler;',
          li16:'Web sitesinin kalitesi kullanıcı gözünde işletme güvenilirliğini %57 oranında etkiliyor.',
          li17:'Bir internet kullanıcısı 0.05 saniye içinde web siteniz hakkında fikir sahibi oluyor.',
          li18:'İnternet kullanıcılarının %74’ü mobil uyumlu web sitesi tercih etme eğilimindedir.',
          li19:'2021 yılında internet trafiğinin %71’i mobil cihazlardan geliyor.',
          li20:' İnternet kullanıcılarının %62’si bir işletmeye ait web sitesinde en önemli unsurun kapsamlı iletişim bilgileri olduğunu söylüyor. Yani web sitesi üzerinde ulaşılabilirlik ziyaretçiler için çok önemli!',
          li21:'Web sitesi ziyaretçilerinin %63’ü vakit geçirirken zorlandıkları web sitesinden ayrılmayı tercih ediyor. Bu da kullanıcı deneyiminin önemini gözler önüne seriyor.',
          text11:'Yukarıdaki verilerin tamamı işletmelerin profesyonel web tasarım hizmetine neden ihtiyaç duyduklarını açıklıyor. Potansiyel müşterileriniz için bir internet sitesine sahip olmanız yeterli olmuyor. İnternet sitenizin güvenilir, kaliteli ve nitelikli olması da gerekiyor. Bunun için de ziyaretçi kitlenizi iyi tanımalı ve onların taleplerine cevap verebilmelisiniz.'
        },
        
      ];
    return(
        <div className='question'>
            <div className='container'>
                <div className="questText">
                    <h2 className='baslik6'> <span style={{color:'#eb2f5b'}}>Web Tasarım</span> Hakkında Sık Sorulan Sorular</h2>
                    <p className='text'>Faydalı Bilgiler ve Sektöre Plaza Yazılım Ajansı Bakışı..</p>
                </div>
                <div className="wrapper ">
                    <div className="accordion container">
                        <ul className='leftMenu'>
                            {accordionData.map((item, index) => (
                            <li key={index} style={{marginBottom:'4rem'}}>
                                <a href={`#${item.id}`} 
                                className={`clickArea title ${selected === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                                >
                                
                                <h4 className='baslik7' id={item.id}>{item.title}</h4>
                                <span className={`accordionIcon ${selected === index ? 'open' : ''}`}><FaAngleRight /></span>
                                </a>
                                <ul className={`frView ${selected === index ? 'open' : ''}`}>
                                    {item.header ?<h2 className='baslik8'>{item.header}</h2>:''}
                                    {item.content?<p className='text3'>{item.content}</p>:''}
                                    {item.header2?<h2 className='baslik8'>{item.header2}</h2>:''}
                                    {item.text1?<p className='text3'>{item.text1}</p>:''}
                                    {item.text3 ? <p className='text3'>Kurumsal web tasarım hizmetlerinde, işletmenizin sektörü, logosu, ürün ve hizmetleri, hedef kitlesi, misyon ve vizyonu gibi birçok faktörü göz önünde bulundurarak projelerimizi oluştururuz. Rakip analizi ve hedef kitle analizi sonuçlarına dayanarak web tasarım çalışmalarımızı şekillendiririz.</p>:''}
                                    {item.li1?<p className='text4'>{item.li1}</p>:''}
                                    {item.li2?<p className='text4'>{item.li2}</p>:''}
                                    {item.li3?<p className='text4'>{item.li3}</p>:''}
                                    {item.li4?<p className='text4'>{item.li4}</p>:''}
                                    {item.li5?<p className='text4'>{item.li5}</p>:''}
                                    {item.li6?<p className='text4 space'>{item.li6}</p>:''}
                                    {item.header3?<h2 className='baslik8'>{item.header3}</h2>:''}
                                    {item.text4 ? <p className='text3'>İnternet kullanımının artmasıyla birlikte, küçük işletmeler bile kolayca kurumsallaşabilir. İşletmenizin imajını yansıtmak ve güçlendirmek için bir kurumsal kimliğe sahip olmanız gereklidir. Potansiyel müşterileriniz, ürün ve hizmetlerinizi satın almadan önce internet üzerinde araştırma yapar. Web siteniz, sizin dijital dünyadaki mağazanızdır. Bu nedenle, kurumsal bir web tasarım hizmeti ile web sitenize özel önem vermelisiniz.</p>:''}
                                    {item.text5 ? <p className='text3'>{item.text5}</p>:''}
                                    {item.text6 ? <p className='text3'>Profesyonel web tasarım hakkında daha fazla bilgi için <strong>bize hemen ulaşın ve görüşelim.</strong> </p>:''}
                                    {item.li7 ?<li className='text4'><p> &#8674; {item.li7} </p></li> : ''}
                                    {item.li8 ?<li className='text4'><p> &#8674; {item.li8} </p></li> : ''}
                                    {item.li9 ?<li className='text4'><p> &#8674; {item.li9} </p></li> : ''}
                                    {item.li10 ?<li className='text4'><p> &#8674; {item.li10} </p></li> : ''}
                                    {item.li11 ?<li className='text4 space'><p> &#8674; {item.li11} </p></li> : ''}
                                    <p className='text3'>{item.text2}</p>
                                    {item.header4?<h2 className='baslik8'>{item.header4}</h2>:''}
                                    {item.text7 ? <p className='text3'>{item.text7}</p>:''}
                                    {item.text8 ? <p className='text3'>{item.text8}</p>:''}
                                    {item.li12?<p className='text4'>&#8674; <strong>Yeni Müşteriler Bulma:</strong> İnternet işletmelerin yeni müşteriler bulma ve daha fazla müşteriye ulaşmada en etkili ve en düşük maliyetli çalışma alanıdır. Bunu da web sitesine sahip olarak başarabilirsiniz.</p>:''}
                                    {item.li13?<p className='text4'>&#8674; <strong>7/24 Ulaşılabilirlik:</strong> Tüketiciler artık istedikleri zaman ulaşabilecekleri işletmeleri tercih ediyorlar. Bir web sitesi ile 7/24 ulaşılabilir olabilirsiniz.</p>:''}
                                    {item.li14?<p className='text4'>&#8674; <strong>Etkili Reklam ve Tanıtım:</strong> Geleneksel reklam ve tanıtım faaliyetlerine olan ilgi her geçen gün azalıyor. Web sitesi işletmelere düşük maliyetlerle etkili reklam ve tanıtım faaliyetleri planlama olanağı verir.</p>:''}
                                    {item.li15?<p className='text4'>&#8674; <strong>Marka İmajı:</strong> Markanızın dijitalde imajı için web sitesi tasarımı çok önemlidir. Etkili ve yüksek kullanıcı deneyimi sağlayan bir web sitesi ile marka imajınız güçlenebilir ve kolaylıkla müşteri bağlılığı oluşturabilirsiniz.</p>:''}
                                    {item.li16?<p className='text4'>&#8674; {item.li16}</p>:''}
                                    {item.text9 ? <p className='text3'>{item.text9}</p>:''}
                                    {item.li17 ?<li className='text4'><p> &#8674; {item.li17} </p></li> : ''}
                                    {item.li18 ?<li className='text4'><p> &#8674; {item.li18} </p></li> : ''}
                                    {item.li19 ?<li className='text4'><p> &#8674; {item.li19} </p></li> : ''}
                                    {item.li20 ?<li className='text4'><p> &#8674; {item.li20} </p></li> : ''}
                                    {item.li21 ?<li className='text4'><p> &#8674; {item.li21} </p></li> : ''}
                                    {item.text11 ? <p className='text3'>{item.text11}</p>:''}
                                </ul>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}




export default Question;