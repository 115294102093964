import React from 'react'
import './Asamalar.css'

// resimler
import Projelendirme from '../Assets/projelendirme.png'
import Test from '../Assets/test.webp'
import Tasarim from '../Assets/tasarim.webp'
import YayinSonrasi from '../Assets/yayinSonrasi.webp'
import shape13 from '../Assets/shape-13.svg'


const Asamalar = () => {
  return (
    <div className='Asamalar'>
        <img src={shape13} className='shape13'  alt="" />
        <div className="container">
            <h2 className='baslik6'>Nasıl Yapıyoruz <strong style={{color:'#eb2f5b'}}>?</strong></h2>
            <div className="widgets">
                <div className="widget">
                    <div className="widgtImg">
                        <img className='shadow-img' src={Projelendirme} alt="projelendirme aşaması" width='180px' height='116px' />
                    </div>
                    <div className="icerik">
                        <h2 className='baslik0 title'>Projelendirme Aşaması</h2>
                    </div>
                        
                        <p className='widgtText'> Doğru belirlenmiş strateji, analizler ve hedeflerin oluşturduğu bir proje başarılı bir web tasarımının en önemli sürecini oluşturur. Bu aşamada firmanın faaliyet gösterdiği sektör analiz edilir.</p>
                </div>
                <div className="widget">
                    <div className="widgtImg">
                        <img className='' src={Tasarim} alt="tasarım süreci" width='180px' height='116px' />
                    </div>
                    <div className="icerik">
                        <h2 className='baslik0 title'>Tasarım Süreci</h2>
                    </div>
                    <p className='widgtText'>Renk paletlerinin seçimi, logo, banner ve sitede kullanılacak görsel öğelerin tasarımlarının yapılması SEO uyumlu web tasarımı projelerinin en önemli basamaklarından biridir. </p>
                </div>
                <div className="widget">
                    <div className="widgtImg">
                        <img className='' src={Test} alt="test süreci" width='180px' height='116px' />
                    </div>
                    <div className="icerik">
                        <h2 className='baslik0 title'>Test Süreci</h2>
                    </div>
                    <p className='widgtText'> Browser testleri, hız testleri, stres testi, site de yalnış ve ya kırık linklerin kontrolü, Web standartlarına uygunluğu,  Tüm ekran çözünürlüklerinde teste tabi tutulması gibi testler yapılır.</p>
                </div>
                <div className="widget">
                    <div className="widgtImg">
                        <img className='shadow-img' src={YayinSonrasi} alt="Yayın sonrası" width='180px' height='116px' />
                    </div>
                    <div className="icerik">
                        <h2 className='baslik0 title'>Yayın Sonrası Aşamalar</h2>
                    </div>
                    <p className='widgtText'>Testleri bitirilen ve son kontrolleri yapılan proje uygun ve hızlı bir hostingle yayına başlar. Sitenin admin paneli ve içerik yönetimi ile ilgili eğitimlerden sonra  site müşterimize teslim edilir.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Asamalar