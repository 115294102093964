import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// icons
import webYazlm from '../../Assets/web-yazlm.svg'
import isSurec from '../../Assets/is-surec-takibi-yazilimlari.svg'
import mobilUygulama from '../../Assets/mobil-uygulama.svg'
import ozelProje from'../../Assets/ozel-proje-gelistirme.svg'
import react from '../../Assets/react-logo.png'
import reactNative from '../../Assets/react-native-logo.png'
import laravel from '../../Assets/laravel.png'
import jquery from '../../Assets/jquery.svg'
import swift from '../../Assets/swift-logo.png'
const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {    
            ScrollTrigger.create( {
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <>
    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title '>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title actived'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/web-yazilim" className='title actived'>
                                        <h4>Web Yazılım</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={webYazlm} alt="web tasarim"  width='142px' height='142px' />
                        <h2 className='baslik9'>Web Yazılım</h2>
                        <p className='text2'>Web Sitesi Tasarımı hizmetimiz, işletmenizin ve markanızın profesyonellik seviyesini
                            yükseltmeye yönelik yenilikçi ve kaliteli web sitesi projelerini hayata geçirme kapasitesine sahiptir.
                        </p>
                        <br />
                        <p className='text2'>
                            İster e-ticaret, 
                            ister kurumsal web sitesi ihtiyacınız olsun, faaliyet alanınıza uygun, kullanıcı deneyimini önceleyen estetik 
                            ve işlevsel web sitelerini deneyimli ekibimizle tasarlıyoruz.
                            Bu hizmetimiz, sizi dijital dünyada üst sıralara taşımayı hedeflerken, markanızı da en iyi şekilde temsil etmeyi garanti eder.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <h2 className='baslik9'>Sunduğumuz Avantajlar</h2>
                        <div className='text2'>
                            <ul>
                                <li>İhtiyaçlarınıza özel kişiselleştirilmiş butik web yazılım projeleri</li>
                                <li>Güvenli ve sürdürülebilir projeler</li>
                                <li>Gereksiz kod yapısından arındırılan kod yapısı sayesinde stabil ve hızlı web yazılım projeleri</li>
                                <li>Çoklu Dil Desteği ile Geniş Kitlelere Ulaşma Olanağı</li>
                                <li>İş süreçlerinizle uyumlu, çalışma sisteminize entegre yazılımlar</li>
                                <li>Aramalarda rakiplerinizi geride bırakarak arama sonuçlarında üst sıralarda yer almanıza yardımcı olur.</li>
                            </ul>
                        </div>    

                    </div>
                    <div className="icerik" >
                        <img className='icon' src={isSurec} alt="Kurumsal web tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>İş Süreç Takibi Yazılımları</h2>
                        <p className='text2'>Tüm ERP ve CRM programlarınız ile entegre şekilde çalışabilen, iş süreçlerinizi takip edebileceğiniz ve ihtiyaçlarınıza 
                            özel şekilde yazılımların üretilebildiği, otomasyon sistemleridir. 
                        </p>
                        <br />
                        <p className="text2">Bu yazılımlar ERP ve CRM programlarından bağımsız bir şekilde, 
                            tümüyle size özel olarak da oluşturulabilir. Bu otomasyon ve takip sistemi sayesinde, firmanızın belirli ihtiyaçlarını otomatik 
                            hale getirebilir ve iş yerinizde oluşan zaman karmaşasının önüne geçebilirsiniz. Tüm talepleriniz ve sorularınız için bize ulaşabilirsiniz.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={mobilUygulama} alt="mobil uygulama tasarımı"  width='142px' height='142px' />
                        <h2 className='baslik9'>Mobil Uygulama</h2>
                        <p className='text2'>Var olan web sitenizin mobil uygulamasını veya isteğinize özel kişisel , kurumsal mobil uygulama
                            hazırlanmasını sağlamaktayız. Web sitenizi, tümüyle bir mobil uygulama haline getirebilir ve istediğiniz özelliklerle donatabilirsiniz. 
                        </p>
                        <br />
                        <p className="text2">
                            Bunun yanı sıra gerçekleştirmek istediğiniz tüm mobil uygulama fikirlerinizi, kodlayabiliriz 
                            ve hayata geçirilmesini sağlayabiliriz. Tüm detaylar ve sorularınız için bizimle irtibata geçebilirsiniz.
                        </p>
                    </div>
                    <div className="icerik">
                        <img src={ozelProje} alt="portfolyo web tasarım"  width='142px' height='142px'/>
                        <h2 className="baslik9">Özel Proje Geliştirme </h2>
                        <p className="text2">Çalışma sisteminize, sektörünüze ve markanızın ihtiyaçlarına özel web ve mobil tabanlı yazılım projelerine ne 
                            dersiniz? Hazır yazılımlar fikirlerinizi ifade etmede ve taleplerinizi karşılamada yetersiz kalır.
                        </p>
                        <br />
                        <p className='text2'>Hayata geçirmek istediğiniz yazılım projenizle ilgili tüm detaylar ekibimiz tarafından dinlenir. 
                            Projelendirme süreci tamamlandıktan sonra sizlere detaylı bir rapor sunulur. Talepleriniz doğrultusunda gerekli 
                            revizyonlar yapılır ve test edilerek projeniz teslim edilir.
                        </p>
                        <br />
                        <p className='text2'>Web yazılım projeniz tüm ihtiyaçlarınıza yaratıcı çözümler getirir. Projenin tasarım çizimi ve kodlama işlemleri 
                            sonrasında tasarım ve yazılım entegrasyonu gerçekleştirilir. Web yazılım projeniz ilerleyen süreçte güncellenen ve değişen 
                            ihtiyaçlarınıza yönelik revize edilebilir.
                        </p>
                    </div>
                    <div className="icerik">
                        <h2 className='baslik9'>Kullandığımız Teknolojiler</h2>
                        <div className="row imageList">
                            <div><img src={laravel} width='180px' height='80px' alt="html logo" /></div>
                            {/* <div><img src={js}  width='180px' height='80px' alt="javascript logo" /></div> */}
                            <div><img src={jquery}  width='180px' height='80px' alt="jquery logo" /></div>
                            <div><img src={react}  width='180px' height='80px' alt="react logo" /></div>
                            <div><img src={reactNative}  width='180px' height='80px' alt="react native logo" /></div>
                            <div><img src={swift}  width='180px' height='80px' alt="swift logo" /></div>
                            
                        </div>
                    </div>
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
  )
}

export default WebTasarim