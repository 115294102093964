import React,{useLayoutEffect,useEffect} from 'react'
import './Home.css'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { TypeAnimation } from 'react-type-animation'
import Cards from '../Cards/Cards'
import HorizontalScroll from '../Addition/HorizontalScroll'
import Jobs from '../Jobs/Jobs'
import Offer from '../Offer/Offer'
import { useGSAP } from '@gsap/react'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import Content from '../Content/Content'
import Question from '../Question/Question'
import Asamalar from '../Asamalar/Asamalar'
const Home = () => {
  

    // background değiştirme
  
  useLayoutEffect(()=>{
    let mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);
    mm.add("(min-width: 100px)", () => {
      const section = document.querySelector("[data-color]"); // Sadece ilgili section'ı seçin
      if (section) {
        const [bgColor, color] = section.getAttribute("data-color").split(" ");
        ScrollTrigger.create({
          trigger: section,
          start: "200 bottom",
          end: "+=60% center",
         
          // markers: true,
          onToggle: self => {
            if (self.isActive) {
              gsap.to("body", {
                backgroundColor: bgColor,
                color: color,
                overwrite: "auto",
              });
            } else {
              gsap.to("body", {
                backgroundColor: "white",
                color: "black",
                overwrite: "auto",
              });
            }
          },
        });
      }

      
  });
  return () => mm.revert();
  });
  
  // background değiştirme
  // header resim path
  useLayoutEffect(() => {
    let mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);
    mm.add('(min-width: 800px)', () => {
      let skews = document.querySelectorAll('.home');
      skews.forEach((skew) => {
        let clip_polygon = gsap.timeline({
          scrollTrigger: {
            trigger: skew,
            start: 'top top',
            scrub: true,
          },
        });
        clip_polygon.fromTo(
          skew,
          {
            clipPath:
              'polygon(0% 0%, 100% 0%, 100% 90%, 100% 100%, 0% 100%, 0% 90%)',
          },
          {
            clipPath:
              'polygon(0% 0%, 100% 0%, 100% 54%, 50% 100%, 50% 100%, 0% 54%)',
          }
        );
      });
    });
    return () => mm.revert();
  });
   // header resim path

  useGSAP(()=>{
    const tl = gsap.timeline();

    tl.from(
      ".homeBackground",
      {
        x:"-100%",
        duration:1,
      }
    )
    tl.from(
      ".baslik",
      0.5,
      {
        opacity:0,
        scale:0.8,
        duration:1,
      }
    )
    tl.from(
      ".baslik2",
     
      {
        opacity:0,
        scale:0.8,
        duration:1,
      }
    )
    

  })
  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>Tekirdağ'ın Web Tasarım Ajansı Plaza Yazılım® | Anasayfa</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
      </Helmet>
    
      <div className='home'>
        <div className="homebackg">
          <div className="homeBackground"><img src="img/light1.jpg" alt="" /></div>
       
        </div>
        <div className='homeText'>
          <div className='spanText'>
            <p className='baslik'>doğru</p>
          </div>
          <div className='spanText2'>
            <p className='baslik2'>WEB TASARIM</p>
          </div>
          <div className='spanText3'>
            <p className='baslikText'>
              <TypeAnimation
                sequence={[
                  2400,
                  "Kurumsal imajı tam anlamıyla yansıtmalı.",
                  2000,
                  "etkileyici ve aynı zamanda sade olmalı",
                  2000,
                  "arama motorlarında ilk sırada olmalı",
                  2000,
                  "tüm cihazlarla tam uyumlu çalışmalı",
                  2000,
                  "ziyaretçilerini müşteriye dönüştürmeli",
                  2000,
                ]}
                speed={50}
                repeat={Infinity}
            /></p>
          <div className='homeButton' ><a href="/teklif-al" className='button text' role='button'>TEKLİF AL</a></div>
          </div>
        </div>
      </div>
          <div className='demo scrollbtn'>
            <a href="#cards"><span></span></a>
          </div>
       <Cards/>
       <Asamalar/>
       <HorizontalScroll/>
       <Content/>
       <Jobs/>
       <Offer/>
       <Question/>
       
    </>
    </HelmetProvider>
  )
}

export default Home