import React from 'react'
import './NavHeader.css';


const Header = (props) => {
  return (
    <div className='navHeader'>
        <div className='contain'>
            <div className="texts">
                <h2 className='baslik2'>{props.title}</h2>
                <p className='text'>{props.text}</p>
            </div>
        </div>
    </div>
  )
}

export default Header