import React from 'react';
import './Footer.css';
import GsapMagnetic from '../GsapMagnetic';

// images
import shape16 from '../Assets/shape-16.svg'

const Footer = () => {
  return (
    <footer >
        <div className='iletisim'>
        <img src={shape16} className='shape16' alt="" />
          <div>
            <p className='text2'>Hala Bir Websiteniz Yok mu ?</p>
            <h2 className='footerHeader baslik6' >Bizimle İletişime Geçin!</h2>
          </div>
            <a href="/teklif-al">
            <GsapMagnetic>
              <button className="button" aria-label='İletişim Butonu'>
              <div className="line one">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
              <div className="line two">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
              <div className="line three">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
                <div className="line four">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
                
                <div className="line five">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
                
                <div className="line six">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
                
                <div className="line seven">
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>
                <div className="round"></div>

              </div>
              </button>
            </GsapMagnetic>
            </a>
        </div>
        <div className='foot'>
          <div className='container'>
            <div className='sec aboutus'>
              <a href=""><img src='img/plazayazilim-logo.svg' alt="logo" width='330px' height='200px' role='logo' aria-label='Anasayfaya Git'/></a>
              
            </div>
            <div className="sec quicklinks">
              <h2>Sayfalar</h2>
              <ul>
                <li><a href="/">Anasayfa</a></li>
                <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                <li><a href="/">Projelerimiz</a></li>
                <li><a href="/iletisim">İletişim</a></li>
              </ul>
            </div>
            <div className="sec quicklinks">
              <h2>Hizmetler</h2>
              <ul>
                <li><a href="/web-tasarim">Web Tasarım</a></li>
                <li><a href="/web-yazilim">Web Yazılım</a></li>
                <li><a href="/dijital-pazarlama">SEO</a></li>
                <li><a href="/e-ticaret">E-Ticaret</a></li>
              </ul>
            </div>
            <div className="sec contact">
              <h2>İletişim</h2>
              <ul className="info">
                <li>
                  <span><i class="fa-solid fa-phone"></i><p><a href="tel:08503097984" data-replace="this link">+90 850 309 7984</a></p></span>
                </li>
                <li>
                  <span><i class="fa-solid fa-phone"></i><p><a href="tel:05459254596" data-replace="this link">+90 545 925 4596</a></p></span>
                </li>
                <li>
                  <span><i class="fa-solid fa-envelope"></i><p><a href="mailto:info@plazayazilim.com">info@plazayazilim.com</a></p></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
          <div className='copyrightText'>
            <div className='container'>
              <p>&copy; 2018-2024 Plaza Yazılım</p>
              <p> Tüm Hakları Saklıdır.</p>
            </div>
          </div>
    </footer>
   
  )
}

export default Footer