import gsap from "gsap";
import React, { useRef ,useEffect} from "react";


export default function GsapMagnetic({children}){
    const ref = useRef(null);

    useEffect (()=>{

        const xTo = gsap.quickTo(ref.current, 'x' , {duration:1 , ease:'elastic.out(.2,.5)'})
        const yTo = gsap.quickTo(ref.current, 'y', {duration:1 , ease:'elastic.out(.2,.5)'})


        const mouseMove =(e) =>{
            const {clientX, clientY} = e;
            const {width,left,top} =ref.current.getBoundingClientRect();
            const x =clientX - (left + width /2);
            const y = clientY - (top + width /2);
            xTo(x)
            yTo(y)
        }

        const mouseLeave =(e) =>{
            xTo(0)
            yTo(0)
        }
        ref.current.addEventListener('mousemove',mouseMove)
        ref.current.addEventListener('mouseleave',mouseLeave)


       
    },[])

    return (
        React.cloneElement(children,{ref})
    )
}