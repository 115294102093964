import React from 'react'
import './Offer.css'



const Offer = () => {
  return (
    <div className='offer'>
      
        <div className='container'>
            <h2 className='baslik6'>Plaza Yazılım , İşinizi sadece Yazılım Değil, bir  <span style={{display:'inline' , color:'#eb2f5b'}}>Ortak</span> Olarak Görür.</h2>
            <p className='text2'>Uzmanlığımızı ve yeteneğimizi, ürün ve hizmetleriniz ile birleştirelim.</p>
            <div className='orn'>
            <a className='contentBtn text' href="/teklif-al">Teklif Al  <ion-icon name="arrow-forward-outline"></ion-icon></a>
            </div>
        </div>
    </div>
  )
}

export default Offer