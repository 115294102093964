import React,{useState,useRef} from 'react'
import './TeklifAl.css'
import emailjs from '@emailjs/browser'
import Header from '../Kurumsal/Header/NavHeader'
import GsapMagnetic from '../GsapMagnetic'


// icons

import dijitalTasarim from '../Assets/dijital-tasarm.svg'
import dijitalDanismanlik from '../Assets/dijital-dansgmanlk.svg'
import eTicaret from '../Assets/e-ticaret.svg'
import webTasarim from '../Assets/web-tasarm.svg'
import webYazlm from '../Assets/web-yazlm.svg'
import mobileApp from '../Assets/mobile-app-2.svg'


const TeklifAl = () => {

    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [number,setNumber] = useState('');
    const [konu,setKonu] = useState('');
    
    
    const form = useRef();
      
    const handleSubmit = (e) =>{
        e.preventDefault();

        const serviceId ='service_yhdgl1p';
        const templateId ='template_qz8pskf';
        const publicKey ='7qmwI-FhI3hgSGtfL';

        const templateParams ={
            from_name:name,
            from_email:email,
            to_name:'Plaza Yazılım',
            message:message,
            number:number,
            konu:konu,
        };

        //send email usein emailJs
        emailjs.send(serviceId,templateId,templateParams,publicKey)
        .then((response) =>{
            console.log('E-mail başarıyla gönderildi !',response);
            setName('');
            setEmail('');
            setMessage('');
            setNumber('');
            setKonu('');
        })
        .catch((error)=>{
            console.error('Mesaj Gönderilemedi:',error)
        });
    }
    
  return (
    <>
   <Header title={'Profesyonel Çözümler'} text={'Geleceğinizi beraber inşaa edelim!'}/>
   <div className="header">
    
        <div class="custom-shape-divider-bottom-1697817279">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>
   </div>
   
    <div className='contactPage teklifAl'>
        <div className="contactForm">
            <div className="container">
                <div className="titles">
                    <h2 className='baslik6'>Tanışalım,ayrıntıları konuşalım.</h2>
                    <p className='text2'>Proje detaylarınızı bizimle paylaşın, en kısa sürede size geri dönüş sağlayalım.</p>
                </div>
               
                    <form ref={form}  onSubmit={handleSubmit} className='adsRow'>
                        <div className="serviceCards">
                            <div className="sCard">
                                <img className='icon' src={webTasarim} alt="web tasarim" width='100px' height='100px' />
                                <p className='text2'>Web Tasarım</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                            <div className="sCard">
                                <img className='icon' src={webYazlm} alt="web yazılım" width='100px' height='100px' />
                                <p className='text2'>Web Yazılım</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                            <div className="sCard">
                                <img className='icon' src={eTicaret} alt="e-ticaret" width='100px' height='100px' />
                                <p className='text2'>E-Ticaret</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                            <div className="sCard">
                                <img className='icon' src={mobileApp} alt="mobil uygulama" width='100px' height='100px' />
                                <p className='text2'>Mobil Uygulama</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                            <div className="sCard">
                                <img className='icon' src={dijitalDanismanlik} alt="dijital pazarlama" width='100px' height='100px' />
                                <p className='text2'>Dijital Pazarlama</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                            <div className="sCard">
                                <img className='icon' src={dijitalTasarim} alt="dijital tasarım" width='100px' height='100px' />
                                <p className='text2'>Dijital Tasarım</p>
                                <label class="checkbox">
                                    <input type="checkbox"/>
                                    <GsapMagnetic>
                                        <svg viewBox="0 0 64 64" height="1.5em" width="1.5em">
                                            <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                                        </svg>
                                    </GsapMagnetic>
                                </label>
                            </div>
                        </div>
                        <div>
                        <div className="input">
                            <input type="text" value={name} required className='form-control' name="name" id='name' placeholder='Ad Soyad*' onChange={(e) => setName(e.target.value)} />
                            <label htmlFor="name" className="form__label">Ad Soyad*</label>
                        </div>
                        <div className="input">
                            <input type="email" value={email} required className='form-control' name="email" id='email' placeholder='E-Posta*'onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email" className="form__label">E-Posta*</label>
                        </div>
                        <div className="input">
                            <input type="text" value={number} required className='form-control' name="number" id='number' placeholder='+90 (555) 111-11-11*'  onChange={(e) => setNumber(e.target.value)} />
                            <label htmlFor="number" className="form__label">+90 (555) 111-11-11*</label>
                        </div>
                        <div className="input">
                            <input type="text" value={konu} className='form-control' name="konu" id='konu' placeholder='Konu' onChange={(e) => setKonu(e.target.value)}  />
                            <label htmlFor="konu" className="form__label">Firma Adı</label>
                        </div>
                        <div className="messageInput">
                            <textarea type='text' value={message} required name="message" id="message" className='form-control messageControl'  autoComplete="off" placeholder='Mesajınızı buraya yazabilirsiniz' rows='3' onChange={(e) => setMessage(e.target.value)}></textarea>
                            <label htmlFor="message" className="form__label">Mesajınızı buraya yazabilirsiniz</label>
                        </div>
                        </div>
                        <div className="orn">
                            <button type='submit' className='contentBtn text' href="">GÖNDER <ion-icon name="arrow-forward-outline"></ion-icon></button>
                            
                        </div>
                    </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default TeklifAl