import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Helmet,HelmetProvider } from 'react-helmet-async'

// icons
import dijitalpazarlama from '../../Assets/dijital-dansgmanlk.svg'
import sosyalMedya from '../../Assets/sosyal-medya-dansgmanlga.svg'
import etkPzarlama from '../../Assets/etkileyici-pazarlama-3.svg'
import webReklam from '../../Assets/web-reklam-yoenetimi.svg'
import seo from '../../Assets/seo-2.svg'

const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
          
            ScrollTrigger.create( {
              
              
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <HelmetProvider>
    <>

    <Helmet>
        <title>Dijital Pazarlama | Plaza Yazılım®</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
    </Helmet>

    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title'>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title actived'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/dijital-pazarlama" className='title actived'>
                                        <h4>Dijital Pazarlama</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={dijitalpazarlama} alt="web tasarim"  width='142px' height='142px' />
                        <h2 className='baslik9'>Dijital Pazarlama</h2>
                        <p className='text2'>Dijital danışmanlık, markanızın internet ortamında daha bilinir hale gelmesini sağlar.  
                            Markanızın, online ortamda daha geniş kitlelere ulaşması, marka itibarının oluşturulması, marka bilinirliğinin 
                            arttırılması dijital danışmanlık hizmeti ile mümkündür.
                        </p>
                        <br />
                        <p className='text2'>
                            Markanızın potansiyelini artıracak; e-ticaret stratejileri belirleme, reklam bütçeleri oluşturma, dijital pazarlama kriz yönetimi gibi 
                            alanlarda Kumsal Ajans ekibi yanınızdadır. Dijital danışmanlık ile pazarlama bütçeniz en verimli şekilde kullanılır.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={etkPzarlama} alt="Kurumsal web tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Etkileyici Pazarlama</h2>
                        <p className='text2'>Etkileyici pazarlama ile ürünlerinizi, hizmetlerinizi ve markanızı sosyal medya fenomenlerinin sosyal medya hesaplarında tanıtmasını sağlıyoruz.  Youtube, Facebook, Twitter, Instagram ve Periscobe gibi sosyal medya platformlarında yüksek takipçi sayısı olan kişiler, içerik oluşturarak ürünleriniz ve markanız hakkında deneyimlerini takipçilerine aktarır.
                        </p>
                        <br />
                        <p className="text2">Etkileyici pazarlama desteğiyle ürünleriniz daha fazla kişiye ulaştırılır. Markanızı daha güçlü hale getirmek için, popüler sosyal medya kullanıcılarının gücünden yararlanabilirsiniz. Ürün ve markanızın tanıtımında reklam içeriği oluşturulur, doğru sosyal medya platformunu seçilir ve marka hedeflerinize uygun sosyal medya fenomenini belirlenir.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={webReklam} alt="e-ticaret sitesi tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Web Reklam Yönetimi</h2>
                        <p className='text2'>Web reklam yönetimi; Google Ads (Arama motoru reklamcılığı), Facebook ve Instagram gibi platformlarda sosyal medya reklamcılığı gibi alanları kapsar. Dijital ortamda hedef kitle davranışları ölçülür. Detaylı anahtar kelime analizi yapılır.
                        </p>
                        <br />
                        <p className="text2">Rakip analizleri ve sektör analizleri yapıldıktan sonra, reklam bütçesi oluşturulur. Aylık reklam maliyeti, erişilecek kitle, kişi sayısı ve reklam türleri sizlere sunulur. Web reklam yönetimi sayesinde, en düşük reklam bütçesiyle en yüksek kitleye erişim sağlanır.
                        </p>
                        <p className="text2">Dijital platformlarda reklam stratejiniz, hedeflerinizle (daha fazla satış yapma, daha fazla müşteri çekme vs.) uyumlu şekilde oluşturulur. Dönüşüm oranları ve diğer verilere göre reklam stratejisi dinamik şekilde geliştirilir.
                        </p>
                    </div>
                    <div className="icerik">
                        <img src={sosyalMedya} alt="sosyalMedya"  width='142px' height='142px'/>
                        <h2 className="baslik9">Sosyal Medya Danışmanlığı</h2>
                        <p className="text2">Sosyal medya danışmanlığı ile sosyal medya platformlarında daha geniş kitleye ulaşılabilir. Kurumsal sosyal medya hesabı oluşturma,  içerik stratejisi oluşturma, ilgi çekici görsel ve etkileşim sağlayacak paylaşımlar düzenleme çalışmalarıyla daha fazla kişiye ulaşmanız sağlanır.
                        </p>
                        <br />
                        <p className='text2'>Facebook, Instagram, Twitter gibi sosyal medya platformlarında aktif olmak önemlidir. Paylaşım takvimi hazırlama ile sosyal medya hesap yönetimi çalışmaları sayesinde, hedef kitlenizin gözünde marka bilinirliğiniz güçlenir.
                        </p>
                        <br />
                        <p className='text2'>Sosyal medya danışmanlığı hizmetleri; hedef kitle, rakip ve sektör analizleriyle yapılır. Hedef kitlenin aktif olduğu zaman dilimlerinde, düzenli bir şekilde ilgi çekici içerikler paylaşarak sosyal medya hesaplarınızın etkileşim oranı arttırılır.
                        </p>
                    </div>
                    <div className="icerik">
                        <img src={seo} alt="SEO"  width='142px' height='142px'/>
                        <h2 className="baslik9">SEO</h2>
                        <p className="text2">Arama motoru, sonuç sayfalarında en üst sıralarda yer alma ve üst sıralardaki konumu korumaya yönelik yapılan çalışmalardır. Öncelikle web sitenizin iç yapısında çeşitli analizler yapılır. Hazırlanan raporlar doğrultusunda, arama motoru kriterlerine uygun olmayan hatalar düzeltilir.
                        </p>
                        <br />
                        <p className='text2'>Web siteniz, arama motoru kurallarına uygun hale geldikten sonra, popüler hale gelmesi için tanıtım çalışmaları yürütülür. SEO çalışmaları öncesinde; rakip analizi, sektör analizi, anahtar kelime analizi, hedef kitle analizi gerçekleştirilir.
                        </p>
                        <br />
                        <p className='text2'>Web sitenizde kullanıcı deneyimini arttıracak optimizasyonlar yapılır. Arama motoru optimizasyonu bir süreçtir. Veri analizleri profesyonel bir bakış açısıyla yapılmalıdır ve verilerden yola çıkarak doğru zamanlamayla hareket edilmelidir. SEO stratejisi oluşturma, uygulama ve geliştirme çalışmaları ile web siteniz üst sıralarda yer alabilir. Arama motoru optimizasyonunun dinamik bir süreç olduğu unutulmamalıdır.
                        </p>
                    </div>
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default WebTasarim