import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Helmet,HelmetProvider } from 'react-helmet-async'

// icons
import eTicaret from '../../Assets/e-ticaret-sitesi-tasarimi.svg'
import girisimci from '../../Assets/girisimci.svg'
import kurumsal from '../../Assets/kurumsal.svg'

const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
          
            ScrollTrigger.create( {
              
              
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <HelmetProvider>
    <>

    <Helmet>
        <title>E-Ticaret | Plaza Yazılım®</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
    </Helmet>

    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title'>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title actived'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/e-ticaret" className='title actived'>
                                        <h4>E-Ticaret</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={eTicaret} alt="web tasarim"  width='142px' height='142px' />
                        <h2 className='baslik9'>E-Ticaret</h2>
                        <p className='text2'>İnternet üzerinden ürün ve hizmet satışı yapabileceğiniz, e-ticaret sitesi tasarımı hizmeti alabilirsiniz. 
                            E-ticaret siteniz, tüm banka ve aracı kurum entegrasyonlarına sahip olur.  Müşterilerinizden kolayca kredi kartı 
                            ile ödeme alabileceğiniz bir e-ticaret platformu tasarlanabilir.
                        </p>
                        <br />
                        <p className='text2'>
                            Mobil cihaz ve tabletlerle uyumlu e-ticaret sitesi, mobil uygulama gibi birçok çözümü Kumsal Ajans ekibiyle projelendirebilirsiniz. 
                            E-ticaret platformları, ürün ve hizmetlerinizin özelliklerine uygun şekilde tasarlanır. Kullanıcı deneyimi yüksek, 
                            modern bir e-ticaret platformu sahibi olabilirsiniz. Tüm e-ticaret sitelerinde ücretsiz SSL güvenlik sertifikası sunulmaktadır.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <h2 className='baslik9'>Sunduğumuz Avantajlar</h2>
                        <div className='text2'>
                            <ul>
                                <li>Kullanıcı deneyimi yüksek e-ticaret sitesi ve mobil uygulama</li>
                                <li>Kolay yönetilebilir e-ticaret sistemi</li>
                                <li>İşlevsel ve kolay yönetilebilir yönetim paneli</li>
                                <li>Taleplerinize uygun özellik entegrasyonları</li>
                                <li>Çoklu Dil Desteği</li>
                            </ul>
                        </div>    

                    </div>
                    <div className="icerik" >
                        <img className='icon' src={eTicaret} alt="e-ticaret sitesi tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>E-Ticaret Sitesi Tasarımı</h2>
                        <p className='text2'>Plaza Yazılım internet üzerinden potansiyel müşterilerinize ulaşıp satış gerçekleştirebileceğiniz
                         e-ticaret sitesi projeleri de yürütmektedir . E- ticaret sitesinde tüm banka ve aracı kurum entegrasyonları gerçekleştirilmektedir.
                        </p>
                        <br />
                        <p className="text2">E-ticaret sitesi tasarımlarımız, profesyonel web tasarım ilkelerine tamamen uygun
                            olarak hazırlanmaktadır. Her bir tasarım projemiz, sektörünüze ve satışını yaptığınız ürünlerin özelliklerine 
                            uyum sağlayacak şekilde özelleştirilir. Web tasarım projelerimizde, renk uyumu ve kurumsal renk kullanımına 
                            büyük bir önem veriyoruz. Bu sayede, markanızın kimliği ile web siteniz arasında mükemmel bir uyum sağlıyoruz.
                        </p>
                        <p className="text2">E-ticaret siteleri mobil cihaz, tablet ve modern internet tarayıcılarıyla uyumlu olarak tasarlanır.
                            Gereksiz kodlama yükünden arındırılan web sitesi ile sayfa yüklenme hızı yüksek e-ticaret sitesine sahip olabilirsiniz.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={kurumsal} alt="kurumsal"  width='142px' height='142px' />
                        <h2 className='baslik9'>Kurumsal</h2>
                        <p className='text2'>E-ticaret siteleri; sektör, ürün ve hizmet grubunuzla uyumlu tasarıma sahip olur. 
                            Platform üzerinde, tüm banka ve aracı kurumlara ait entegrasyonlar sağlanır. 
                            Markanızın, kurumsal yapısıyla uyumlu e-ticaret sitesinde, özel taleplerinizin uygulanabileceği bir altyapı bulunur. 
                        </p>
                        <br />
                        <p className="text2">
                            E-ticaret sitesi ile ürün ve hizmetlerinizi daha geniş kitlelere ulaştırabilirsiniz. Kurumsal e-ticaret sitesi, 
                            marka renklerinize uygun, sade ve modern bir tasarıma sahiptir.
                            Kurumsal e-ticaret siteleri, gereksiz kod yapısından arındırıldığı için, sayfa yükleme hızı yüksek bir e-ticaret siteniz olabilir.
                        </p>
                    </div>
                    <div className="icerik">
                        <img src={girisimci} alt="girisimci"  width='142px' height='142px'/>
                        <h2 className="baslik9">Girisimci</h2>
                        <p className="text2">İnternet üzerinden, kişisel olarak ürün ve hizmet pazarlayabileceğiniz bir e-ticaret sitesi tasarlıyoruz. 
                            Stoksuz e-ticaret ve C2C ticari faaliyetleri yürütebileceğiniz e-ticaret projeleri ile gelirlerinizi arttırabilirsiniz.
                        </p>
                        <br />
                        <p className='text2'>Girişimciler için geliştirilen e-ticaret platformları, tüm mobil cihazlar ve tabletler ile uyumludur.
                            E-ticaret sitesi, kolay yönetilebilir Panel ile teslim edilmektedir. Hitap ettiğiniz sektöre özel tasarımlarla, kullanıcı deneyimi, 
                            en üst seviyeye çıkarılır.
                        </p>
                        <br />
                        <p className='text2'>Pazaryeri entegrasyonlarına sahip e-ticaret platformları ile düşük bütçelerle dijital kanallar üzerinden gelir 
                            elde edebilirsiniz. e-ticaret sitesi, ihtiyaçlarınız olan tüm özelliklere ve entegrasyonlara sahip şekilde teslim edilir.

</p>
                    </div>
                    
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default WebTasarim