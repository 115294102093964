import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Helmet,HelmetProvider } from 'react-helmet-async'

// icons
import logo from '../../Assets/logo.svg'
import katalog from '../../Assets/katalog.svg'
import sosyalMedya from '../../Assets/sosyal-medya-kitleri.svg'
import dijitalTasarim from '../../Assets/dijital-tasarm.svg'

const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
          
            ScrollTrigger.create( {
              
              
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <HelmetProvider>
    <>

    <Helmet>
        <title>Dijital Tasarım | Plaza Yazılım®</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
    </Helmet>

    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title'>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title actived'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/dijital-tasarim" className='title actived'>
                                        <h4>Dijital Tasarım</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={dijitalTasarim} alt="dijital tasarım"  width='142px' height='142px' />
                        <h2 className='baslik9'>Dijital Tasarım</h2>
                        <p className='text2'>Markanızın ihtiyacı olan tüm kurumsal tasarımları özenle hazırlıyoruz. Kurumsal kimliğinizi oluşturma ve 
                            marka bilinirliğinizi ileriye taşımanız adına, hizmetler ve tasarımlar sunuyoruz.
                        </p>
                        <br />
                        <p className='text2'>
                            Kurumsal kimlik tasarımının ilk aşaması, logo tasarımıdır. Kurumsal kimlik tasarımı için; 
                            logo, kurum sloganı, broşür, promosyon ürünleri, antetli kağıt, 
                            kartvizit gibi markanızın görsel yüzünü tasarlıyoruz. Reklam ve grafik tasarımı çalışmalarıyla markanızı güçlendirebilirsiniz.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <h2 className='baslik9'>Sunduğumuz Avantajlar</h2>
                        <div className='text2'>
                            <ul>
                                <li>Markanızın kurumsal kimlik tasarımı çalışmalarını yürütüyoruz.</li>
                                <li>Markanızı bilinir hale getirecek kaliteli promosyon ürünleri tasarlıyor ve üretiyoruz.</li>
                                <li>İşlevsel ve kolay yönetilebilir yönetim paneli</li>
                                <li>E-posta pazarlama stratejinizi güçlendirebilirsiniz.</li>
                                <li>Markanız için kurumsal sosyal medya hesapları oluşturabiliriz.</li>
                                <li>Markanızı güçlendirecek bir logo tasarlıyoruz.</li>
                            </ul>
                        </div>    

                    </div>
                    <div className="icerik" >
                        <img className='icon' src={logo} alt="logo tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Logo</h2>
                        <p className='text2'>Kurumsal kimlik tasarımı sürecinin  ilk adımı, logo çizimi yapılmasıdır.  
                            Logo çizimi öncesinde, işletmenizin hedefleri ve kurumsal yapısı için bilgi alışverişi gerçekleştirilir.  
                            Kullanılacak renkler,  talebe göre logo simgesi belirlenir.
                        </p>
                        <br />
                        <p className="text2">Verilen briefler doğrultusunda, logo tasarımı çalışması tamamlanır. Geliştirilen logo sizlere sunulur. 
                            Talepleriniz doğrultusunda son düzenlemeler yapılır ve logonuzun son hali teslim edilir.
                        </p>
                        <br />
                        <p className="text2">Logo tasarımı sırasında, renk kombinasyonu dengesi sağlanır ve markanızın mesajı, 
                            hedef kitleye ulaştırılarak markanızın, akılda kalıcı hale gelmesi sağlanır.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={katalog} alt="katalog tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Katalog</h2>
                        <p className='text2'>İşletmenizin, ürün ve hizmetlerinin tanıtımı için gerçekleştirilen çalışmalardır. 
                            Katalog tasarımı, markanızın kurumsal kimliğine uygun şekilde yapılır.  
                            Katalog tasarımında kaliteli ve ilgi çekici görseller seçilir ve yazı karakteriyle bir ahenk içinde sunulur.
                        </p>
                        <br />
                        <p className="text2">Kataloglarınızda, kurum renklerinize yer verilir ve markanızın akılda kalıcı hale gelmesi sağlanır. 
                            Ürün ve hizmetleriniz her yönüyle inceleyen kişiye aktarılır.
                        </p>
                        <p className="text2">E-ticaret siteleri mobil cihaz, tablet ve modern internet tarayıcılarıyla uyumlu olarak tasarlanır.
                            Gereksiz kodlama yükünden arındırılan web sitesi ile sayfa yüklenme hızı yüksek e-ticaret sitesine sahip olabilirsiniz.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={sosyalMedya} alt="sosyal medya kitleri"  width='142px' height='142px' />
                        <h2 className='baslik9'>Sosyal Medya Kitleri</h2>
                        <p className='text2'>Markanızın, sosyal medya hesaplarında kurumsal kimliğini yansıtacak; 
                            paylaşım planı, kapak tasarımı, profil resmi tasarımı, içerik paylaşım düzeni gibi öğelerdir.
                        </p>
                        <br />
                        <p className="text2">
                            Sosyal medya kitleri, markanızın; Facebook, Instagram, Youtube gibi sosyal medya platformlarında, kurumsal kimliğini ve marka imajını oluşturur. 
                            Sosyal medya kitleri sayesinde, markanıza alan güven artar, marka-müşteri etkileşimi güçlenir ve markanıza olan talep çoğalır.
                        </p>
                    </div>
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default WebTasarim