import React from 'react'
import Header from '../Header/NavHeader'
import './Services.css'

// icons
import dijitalTasarim from '../../Assets/dijital-tasarm.svg'
import dijitalDanismanlik from '../../Assets/dijital-dansgmanlk.svg'
import eTicaret from '../../Assets/e-ticaret.svg'
import webTasarim from '../../Assets/web-tasarm.svg'
import webYazlm from '../../Assets/web-yazlm.svg'
import mobileApp from '../../Assets/mobile-app-2.svg'
import { Helmet,HelmetProvider } from 'react-helmet-async'



const Services = () => {
  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>Hizmetlerimiz | Plaza Yazılım®</title>
        <meta name="description" content="Web tasarım, ulusal ağ ortamlarında yayınlanan web sitelerinin oluşturulma sürecidir. Amaç kurumların kimliksel sürecini tamamlamak ve tanıtım faaliyet..."></meta>
        <meta property="og:description" content="Web tasarım, ulusal ağ ortamlarında yayınlanan web sitelerinin oluşturulma sürecidir. Amaç kurumların kimliksel sürecini tamamlamak ve tanıtım faaliyet..."></meta>
        <meta property="og:url" content="https://www.plazayazilim.com/isin-mutfagi/"></meta>
      </Helmet>
      <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
      <div className='services'>
        <div className="container">
          <div className='serviceCards'>
              <div className="sCard">
                <div className='icons'><a href="/web-tasarim"><img className='icon' src={webTasarim} alt="web tasarim" /></a></div>
                <h2 className="title baslik9"><a href="/web-tasarim">Web Tasarım</a></h2>
              </div>
              <div className="sCard"> 
                <div className='icons'><a href="/web-yazilim"><img src={webYazlm} alt="web yazilim" /></a></div>
                <h2 className="title baslik9"><a href="/web-yazilim">Web Yazılım</a></h2>
              </div>
              <div className="sCard"> 
                <div className='icons'><a href="/dijital-tasarim"><img src={dijitalTasarim} alt="dijital tasarim" /></a></div>
                <h2 className="title baslik9"><a href="/dijital-tasarim">Dijital Tasarım</a></h2>
              </div>
              <div className="sCard"> 
                <div className='icons'><a href="/e-ticaret"><img src={eTicaret} alt="e-ticaret" /></a></div>
                <h2 className="title baslik9"><a href="/e-ticaret">E-Ticaret</a></h2>
              </div>
              <div className="sCard"> 
                <div className='icons'><a href="/mobil-uygulama"><img src={mobileApp} alt="mobil uygulama" /></a></div>
                <h2 className="title baslik9"><a href="/mobil-uygulama">Mobil Uygulama</a></h2>
              </div>
              <div className="sCard"> 
                <div className='icons'><a href="/dijital-pazarlama"><img src={dijitalDanismanlik} alt="dijital danışmanlık" /></a></div>
                <h2 className="title baslik9"><a href="/dijital-pazarlama">Dijital Pazarlama</a></h2>
              </div>
          </div>
        </div>
      </div>
      
    </>
    </HelmetProvider>
  )
}

export default Services