'use client';
import React ,{ useRef,useEffect} from 'react'
import './HorizontalScroll.css'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/ScrollTrigger';

const HorizontalScroll = () => {
   
//   useLayoutEffect(()=>{
  
//     let mm = gsap.matchMedia();
//     gsap.registerPlugin(ScrollTrigger);
//     mm.add("(min-width: 1024px)", () => {
//         const races = document.querySelector(".horizontalScrollWrapper");
       
        
//         function getScrollAmount() {
//             let racesWidth = races.scrollWidth;
//             return -(racesWidth - window.innerWidth);
//         }
        
//         const tween = gsap.to(races, {
//             x: getScrollAmount,
//             duration: 3,
//             ease: "none",
//         });
        
        
//         ScrollTrigger.create({
//             trigger:".horizontalScroll",
//             start:"top 30%",
//             end: () => `+=${getScrollAmount() * -1}`,
//             pin:true,
//             animation:tween,
//             scrub:1,
//             invalidateOnRefresh:true,
//             // markers:true
//         })
//   });
//   return () => mm.revert();
//   });


const firstText = useRef(null);
const secondText = useRef(null);
const slider = useRef(null);

let direction = 1;
let xPercent = 0;


useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    requestAnimationFrame(animation);

    gsap.to(slider.current,{
        scrollTrigger:{
            trigger:document.documentElement,
            start:"top center",
            end:"35% 50%",
            scrub:1,
            onUpdate: e => direction= e.direction * 1,
            
        },
        x:'950px'
    })

});
const animation = () => {

    if(xPercent < -100){

      xPercent = 0;

    }

    else if(xPercent > 0){

      xPercent = -100;

    }

    gsap.set(firstText.current, {xPercent: xPercent})

    gsap.set(secondText.current, {xPercent: xPercent})

    requestAnimationFrame(animation);

    xPercent += 0.02 * direction;

  }
  
  return (
    <div className='horizontalScroll'>
        {/* <div className='horizontalScrollWrapper'>
            <span> <strong className='r2' style={{color:'#eb2f5b'}}>Dijital</strong> <strong className='r3'>Dönüşümünüzü</strong>  <strong className='r4' style={{color:'#eb2f5b'}}>Tasarlıyor,</strong> <strong className='r5'>Yönlendiriyor</strong> <strong className='r6' style={{color:'#eb2f5b'}}>ve</strong> <strong className='r7'>Güçlendiriyoruz.</strong></span>
        </div> */}
        {/* Resim veya vektör eklenecek */}
            {/* <div className='hori-img' >

                <img src={horiİmg} alt="" />
            </div> */}
        
        
        <div ref={slider} className='horizontal2'>
            <div ref={firstText}>
                <span className='no-st-text'> Web Tasarım </span>
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText' > Web Yazılım </span> 
                <div className='htmlTag'><i class="fa-solid fa-mug-hot"></i></div> 
                <span className='no-st-text'> E-Ticaret </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Dijital Pazarlama </span> 
                <div className='htmlTag'><i class="fa-solid fa-award"></i></div> 
                <span className='no-st-text'> Mobil Uygulama </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Sosyal Medya Yönetimi </span> 
                <div className='htmlTag'><i class="fa-regular fa-thumbs-up"></i></div> 
                <span className='no-st-text'> Web Tasarım </span>
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText' > Web Yazılım </span> 
                <div className='htmlTag'><i class="fa-solid fa-mug-hot"></i></div> 
                <span className='no-st-text'> E-Ticaret </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Dijital Pazarlama </span> 
                <div className='htmlTag'><i class="fa-solid fa-award"></i></div> 
                <span className='no-st-text'> Mobil Uygulama </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Sosyal Medya Yönetimi </span> 
                <div className='htmlTag'><i class="fa-regular fa-thumbs-up"></i></div> 
            </div>
            <div ref={secondText}>
                <span className='no-st-text'> Web Tasarım </span>
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText' > Web Yazılım </span> 
                <div className='htmlTag'><i class="fa-solid fa-mug-hot"></i></div> 
                <span className='no-st-text'> E-Ticaret </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Dijital Pazarlama </span> 
                <div className='htmlTag'><i class="fa-solid fa-award"></i></div> 
                <span className='no-st-text'> Mobil Uygulama </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Sosyal Medya Yönetimi </span> 
                <div className='htmlTag'><i class="fa-regular fa-thumbs-up"></i></div> 
                <span className='no-st-text'> Web Tasarım </span>
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText' > Web Yazılım </span> 
                <div className='htmlTag'><i class="fa-solid fa-mug-hot"></i></div> 
                <span className='no-st-text'> E-Ticaret </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Dijital Pazarlama </span> 
                <div className='htmlTag'><i class="fa-solid fa-award"></i></div> 
                <span className='no-st-text'> Mobil Uygulama </span> 
                <div className='htmlTag'><i class="fa-solid fa-code"></i></div> 
                <span className='strokeText'> Sosyal Medya Yönetimi </span> 
                <div className='htmlTag'><i class="fa-regular fa-thumbs-up"></i></div> 
                
            </div>
            
        </div>
       
    </div>
  )
}

export default HorizontalScroll