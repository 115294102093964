import React,{useState,useRef,useEffect} from 'react'
import './Contact.css'
import Offer from '../Offer/Offer'
import emailjs from '@emailjs/browser'
import GsapMagnetic from '../GsapMagnetic'



const Contact = () => {

    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [number,setNumber] = useState('');
    const [konu,setKonu] = useState('');
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [loading, setLoading] = useState(false); 
    const [buttonColor, setButtonColor] = useState('');
  
    useEffect(() => {
        if (notification.message) {
            const timeout = setTimeout(() => {
                setNotification({ message: '', type: '' });
                setButtonColor('');
            }, 3000);
          
            return () => clearTimeout(timeout);
        }
    }, [notification]);

    
    const form = useRef();
    
      
    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);

        const serviceId ='service_yhdgl1p';
        const templateId ='template_qz8pskf';
        const publicKey ='7qmwI-FhI3hgSGtfL';

        const templateParams ={
            from_name:name,
            from_email:email,
            to_name:'Plaza Yazılım',
            message:message,
            number:number,
            konu:konu,
        };
        
       
           


        //send email usein emailJs
        emailjs.send(serviceId,templateId,templateParams,publicKey)
        .then((response) =>{
            console.log('E-mail başarıyla gönderildi !',response);
            setNotification({ message: 'E-posta gönderildi !', type: 'success' });
            setButtonColor('green');
            setName('');
            setEmail('');
            setMessage('');
            setNumber('');
            setKonu('');
        })
        .catch((error)=>{
            console.error('Mesaj Gönderilemedi:',error)
            setNotification({ message: 'E-posta gönderilirken bir hata oluştu.', type: 'error' });
            setButtonColor('red');
        })
        .finally(() => {
            setLoading(false); // Gönderim tamamlandı   ğında loading durumunu devre dışı bırak
        });
        
    }

  return (
    <div className='contactPage'>
        <div className='contactHeader'>
            <div className='contactContain'>
                <div className="contactTexts">
                    <h2 className='baslik2'>İletişim</h2>
                    <p className='text'>Diğerlerinden farklı bir websiteniz olsun !</p>
                </div>
            </div>
        </div>
        <Offer/>
        <div className="contactForm">
            <div className="container">
                <h2 className='baslik6'>İletişim Formu</h2>
               
                    <form ref={form}  onSubmit={handleSubmit} className='adsRow'>
                        <div className="input">
                            <input type="text" value={name} required className='form-control' name="name" id='name' placeholder='Ad Soyad*' onChange={(e) => setName(e.target.value)} />
                            <label htmlFor="name" className="form__label">Ad Soyad*</label>
                        </div>
                        <div className="input">
                            <input type="email" value={email} required className='form-control' name="email" id='email' placeholder='E-Posta*'onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email" className="form__label">E-Posta*</label>
                        </div>
                        <div className="input">
                            <input type="text" value={number} required className='form-control' name="number" id='number' placeholder='+90 (555) 111-11-11*'  onChange={(e) => setNumber(e.target.value)} />
                            <label htmlFor="number" className="form__label">+90 (555) 111-11-11*</label>
                        </div>
                        <div className="input">
                            <input type="text" value={konu} className='form-control' name="konu" id='konu' placeholder='Konu' onChange={(e) => setKonu(e.target.value)}  />
                            <label htmlFor="konu" className="form__label">Konu</label>
                        </div>
                        <div className="messageInput">
                            <textarea type='text' value={message} required name="message" id="message" className='form-control messageControl'  autoComplete="off" placeholder='Mesajınızı buraya yazabilirsiniz' rows='3' onChange={(e) => setMessage(e.target.value)}></textarea>
                            <label htmlFor="message" className="form__label">Mesajınızı buraya yazabilirsiniz</label>
                        </div>
                       

                        <div className="orn">
                            
                            <button type='submit' href="" className={`contentBtn text ${buttonColor}`} disabled={loading}> {loading && <div class="dot-spinner">
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>}
                        {!loading && notification.message && <span>{notification.message}</span>}
                        {!loading && !notification.message && <span>Gönder</span>}</button>
                        </div>
                    </form>
            </div>
        </div>
        <div className='contact-section'>
            <div className='container'>
                <div className="contact-section-info">
                    <h2 className='baslik6'><span style={{display:'inline', color:'#2f5beb'}}>Hayallerinizi</span> <span style={{display:'inline', color:'#eb2f5b'}}>Gerçeğe</span> Dönüştürelim.</h2>
                    <div className="contactText">
                        <div className="icons">
                            <GsapMagnetic>
                                <svg className="feather feather-phone" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/></svg>
                            </GsapMagnetic>
                        </div>
                        <p className='text2'>+90 545 925 4596</p>
                    </div>
                    <br />
                    <div className="contactText">
                            <div className="icons">
                                <GsapMagnetic>
                                    <svg fill='none' strokeWidth="2.4" stroke="currentColor"  width='25' height='28' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs></defs><title/><g data-name="36-mail" id="_36-mail"><rect  height="22" width="30" x="1" y="5"/><polyline  points="1 5 16 20 31 5"/><line  x1="1" x2="12" y1="27" y2="16"/><line x1="31" x2="20" y1="27" y2="16"/></g></svg>
                                </GsapMagnetic>
                            </div>
                            <p className='text2'> <strong>info</strong>@plazayazilim.com</p>
                    </div>
                    
                    <br />
                    <div className="contactText">
                        <div className="icons">
                            <GsapMagnetic>
                                <svg width='25' height='28' fill='none' strokeWidth="2" stroke="currentColor"  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg>
                            </GsapMagnetic>
                        </div>
                            <p className='text2'>Reşadiye, aralık, Mandıracı 2. Sk. No:8 </p>
                    </div>
                    
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12015.689909479655!2d27.784668520847035!3d41.15804290044081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b4e769fcc4055b%3A0x53ad33cef2a3f57d!2sPlaza%20Yaz%C4%B1l%C4%B1m%20%7C%20Web%20Tasar%C4%B1m%20SEO%20ve%20Reklam%20Ajans%C4%B1!5e0!3m2!1str!2str!4v1697645080019!5m2!1str!2str" width="100%" height="450"  style={{border:'none'}} loading="lazy" title='Plaza Yazılım Konumu'></iframe>
            </div>
        </div>
       
    </div>
  )
}

export default Contact