import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Helmet,HelmetProvider } from 'react-helmet-async'

// icons
import webTasarim from '../../Assets/web-tasarm.svg'
import kurumsalTasarım from '../../Assets/kurumsal-web-sitesi-tasarimi.svg'
import mobilUygulama from '../../Assets/mobil-uygulama.svg'
import eTicaret from '../../Assets/e-ticaret-sitesi-tasarimi.svg'
import portfolyo from '../../Assets/portfolyo-web-sitesi-tasarm.svg'
import html from '../../Assets/html.svg'
import react from '../../Assets/React.svg'
import jquery from '../../Assets/jquery.svg'
import js from '../../Assets/js.svg'
import vuejs from '../../Assets/vuejs.svg'
import php from '../../Assets/php.svg'

const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
          
            ScrollTrigger.create( {
              
              
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <HelmetProvider>
    <>

    <Helmet>
        <title>Web Tasarım | Plaza Yazılım®</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
    </Helmet>

    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title actived'>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/web-tasarim" className='title actived'>
                                        <h4>Web Tasarım</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={webTasarim} alt="web tasarim"  width='142px' height='142px' />
                        <h2 className='baslik9'>Web Tasarım</h2>
                        <p className='text2'>Web Sitesi Tasarımı hizmetimiz, işletmenizin ve markanızın profesyonellik seviyesini
                            yükseltmeye yönelik yenilikçi ve kaliteli web sitesi projelerini hayata geçirme kapasitesine sahiptir.
                        </p>
                        <br />
                        <p className='text2'>
                            İster e-ticaret, 
                            ister kurumsal web sitesi ihtiyacınız olsun, faaliyet alanınıza uygun, kullanıcı deneyimini önceleyen estetik 
                            ve işlevsel web sitelerini deneyimli ekibimizle tasarlıyoruz.
                            Bu hizmetimiz, sizi dijital dünyada üst sıralara taşımayı hedeflerken, markanızı da en iyi şekilde temsil etmeyi garanti eder.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <h2 className='baslik9'>Sunduğumuz Avantajlar</h2>
                        <div className='text2'>
                            <ul>
                                <li>Gelişmiş kullanıcı deneyimi (UX) sunan web tasarım çalışmaları</li>
                                <li>Mobil Uyumlu Web Sitesi Tasarımı ile Her Cihaza Uyum</li>
                                <li>İşlevsel ve kolay yönetilebilir yönetim paneli</li>
                                <li>Çoklu Dil Desteği ile Geniş Kitlelere Ulaşma Olanağı</li>
                                <li>Wireframe çalışma sistemi</li>
                                <li>Google Lighthouse üzerinde yüksek puanlamaya sahip web siteleri</li>
                            </ul>
                        </div>    

                    </div>
                    <div className="icerik" >
                        <img className='icon' src={kurumsalTasarım} alt="Kurumsal web tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Kurumsal Web Sitesi Tasarımı</h2>
                        <p className='text2'>İşletmenizin çalışma sistemi, vizyonu, misyonu ve kurumsal yapısı ile uyumlu modern
                            dokuya sahip web sitesi tasarımları oluşturuyoruz. Tüm web tasarım projelerimizde ziyaretçilerinizin web
                            sitenizde kolay işlem yapabilmesine ve markanıza daha kolay ulaşabilmesine odaklanılır.  Arama motoru dostu yazı
                            karakteri  seçilip  kurumsal kimliğinizi barındıran bir web sitesi tasarlanır.
                        </p>
                        <br />
                        <p className="text2">Web tasarım projelerimizde, web sitesi güvenliğini ücretsiz olarak sunan SSL güvenlik sertifikası mevcuttur. 
                            Plaza Yazılım olarak taleplerinizi temel alarak, markanıza özel tasarım çalışmalarıyla kişiselleştirilmiş bir web sitesine sahip olabilirsiniz.
                            Bu sayede hem güvenliği hem de özgünlüğü aynı çatı altında buluşturmuş olursunuz.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={eTicaret} alt="e-ticaret sitesi tasarımı"  width='142px' height='142px'/>
                        <h2 className='baslik9'>E-Ticaret Sitesi Tasarımı</h2>
                        <p className='text2'>Plaza Yazılım internet üzerinden potansiyel müşterilerinize ulaşıp satış gerçekleştirebileceğiniz
                         e-ticaret sitesi projeleri de yürütmektedir . E- ticaret sitesinde tüm banka ve aracı kurum entegrasyonları gerçekleştirilmektedir.
                        </p>
                        <br />
                        <p className="text2">E-ticaret sitesi tasarımlarımız, profesyonel web tasarım ilkelerine tamamen uygun
                            olarak hazırlanmaktadır. Her bir tasarım projemiz, sektörünüze ve satışını yaptığınız ürünlerin özelliklerine 
                            uyum sağlayacak şekilde özelleştirilir. Web tasarım projelerimizde, renk uyumu ve kurumsal renk kullanımına 
                            büyük bir önem veriyoruz. Bu sayede, markanızın kimliği ile web siteniz arasında mükemmel bir uyum sağlıyoruz.
                        </p>
                        <p className="text2">E-ticaret siteleri mobil cihaz, tablet ve modern internet tarayıcılarıyla uyumlu olarak tasarlanır.
                            Gereksiz kodlama yükünden arındırılan web sitesi ile sayfa yüklenme hızı yüksek e-ticaret sitesine sahip olabilirsiniz.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={mobilUygulama} alt="mobil uygulama tasarımı"  width='142px' height='142px' />
                        <h2 className='baslik9'>Mobil Uygulama Tasarımı</h2>
                        <p className='text2'>Mobil Uygulama Tasarımı hizmetimizle, kullanıcı deneyimini önceliklendiren yüksek kaliteli 
                            mobil uygulamalar tasarlamaktayız. İşlevsel, verimli ve etkileşimli uygulamalar oluşturmak için öncelikle detaylı 
                            bir tasarım şeması ve işleyiş sistemi oluşturuyoruz. Bu sayede, ihtiyaçlarınıza göre tam olarak belirlenen yapıya sahip,
                            kullanıcı dostu uygulamalar oluşturmanızı kolaylaştırıyoruz. 
                        </p>
                        <br />
                        <p className="text2">
                            Geliştireceğiniz mobil uygulamanın her aşamasında size destek vererek, 
                            profesyonel mobil uygulama tasarımı hizmeti sunuyoruz.
                        </p>
                    </div>
                    <div className="icerik">
                        <img src={portfolyo} alt="portfolyo web tasarım"  width='142px' height='142px'/>
                        <h2 className="baslik9">Portfolyo Web Sitesi Tasarımı</h2>
                        <p className="text2">Portfolyo web sitesi tasarımı ile işlerinizi profesyonel bir şekilde sunma fırsatını yakalayın. En çarpıcı projelerinizi, 
                            saygın ürün ve hizmetlerinizi sergileyebileceğiniz bu platform, sizlere estetik ve çekici bir alanda takdim etme imkanı sağlar. 
                            Bu web sitesi tasarımı, projelerinizi ve hizmetlerinizi görsel bir şölen ile birleştirerek, hedef kitlenizin dikkatini çekmenize yardımcı olur.
                        </p>
                        <br />
                        <p className='text2'>Portfolyo web sitesi tasarımı ile çalışmalar profesyonel bir düzenle sergilenir. 
                            Güçlü altyapı sayesinde çalışmalarınızı sorunsuz yükleyebilirsiniz. 
                            Sistem üzerinde resim boyutlarını özelleştirebilir ve çalışmalarınızı istediğiniz düzende sergileyebilirsiniz.
                        </p>
                        <br />
                        <p className='text2'>Portfolyo web sitelerimizi uyumlu renk seçenekleri ile dikkat çeken tasarımlarla oluşturuyoruz. 
                            Ayrıca, arama motorları tarafından sevilen yazı fontları kullanarak SEO uyumlu bir deneyim sunarlar. Tüm projelerimizde, 
                            web sitenizin güvenliğini artırmak amacıyla ücretsiz bir SSL sertifikası sunuyoruz. 
                            Bu sayede marka otoritenizi güçlendirebileceğiniz ve tamamen kişiselleştirilebilir portfolyo web sitesi tasarım projeleri sunuyoruz.</p>
                    </div>
                    <div className="icerik">
                        <h2 className='baslik9'>Kullandığımız Teknolojiler</h2>
                        <div className="row imageList">
                            <div><img src={html} width='180px' height='80px' alt="html logo" /></div>
                            <div><img src={js}  width='180px' height='80px' alt="javascript logo" /></div>
                            <div><img src={jquery}  width='180px' height='80px' alt="jquery logo" /></div>
                            <div><img src={react}  width='180px' height='80px' alt="react logo" /></div>
                            <div><img src={php}  width='180px' height='80px' alt="php logo" /></div>
                            <div><img src={vuejs}  width='180px' height='80px' alt="vuejs logo" /></div>
                            
                        </div>
                    </div>
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default WebTasarim