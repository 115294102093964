import React,{useLayoutEffect} from 'react'
import './WebTasarim.css'
import {FaAngleRight} from 'react-icons/fa'
import Header from '../Header/NavHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Helmet,HelmetProvider } from 'react-helmet-async'

// icons
import crossPlatform from '../../Assets/cross-platform-2.svg'
import android from '../../Assets/android-apps.svg'
import ios from '../../Assets/ios-apps.svg'
import mobileApp from '../../Assets/mobile-app-2.svg'

const WebTasarim = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
          
            ScrollTrigger.create( {
                pin: '.leftMenu',
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.icerikler',
                id: 'pinned',
                endTrigger: '.product',
                invalidateOnRefresh: true,
              
            });
         
        });
        return () => mm.revert();
      });

  return (
    <HelmetProvider>
    <>

    <Helmet>
        <title>Mobil Uygulama | Plaza Yazılım®</title>
        <meta property="og:description" content="Web tasarım ve web yazılım hizmetleri veren, web sitesi yapan bir firma. Bizden web sitesi kurma hizmeti alabilirsiniz. Firmamız Çorlu'dadır."></meta>
    </Helmet>

    <Header title={'Hizmetlerimiz'} text={'Uzman Ekip Profesyonel Hizmet'}/>
    <div className='webTasarim services'>
        <div className="container">
            <div className="webTasarimlists">
                <div className="leftMenu-fixed">
                    <div>
                        <ul className="leftMenu d-none">
                            <li className='jobList' >
                                <a href="/web-tasarim" className='title'>
                                    <h4>Web Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/web-yazilim" className='title'>
                                    <h4>Web Yazılım</h4>
                                    <span className='accordionIcon' ><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-tasarim" className='title'>
                                    <h4>Dijital Tasarım</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/e-ticaret" className='title'>
                                    <h4>E-Ticaret</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/mobil-uygulama" className='title actived'>
                                    <h4>Mobil Uygulama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                            <li className='jobList'><a href="/dijital-pazarlama" className='title'>
                                    <h4>Dijital Pazarlama</h4>
                                    <span className='accordionIcon'><FaAngleRight /></span>
                                </a>
                            </li>
                        </ul>
                        <div className='leftMenu resp-d-none'>
                            <ul>
                                <li className='jobList '><a href="/mobil-uygulama" className='title actived'>
                                        <h4>Mobil Uygulama</h4>
                                        <span className='accordionIcon'><FaAngleRight /></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="icerikler">
                    <div className="icerik" > 
                        <img className='icon' src={mobileApp} alt="mobil uygulama"  width='142px' height='142px' />
                        <h2 className='baslik9'>Mobil Uygulama</h2>
                        <p className='text2'>Markanızın mobil varlığını güçlendirecek, tüm mobil uygulama projelerinizi, özenle tasarlıyoruz.  
                            Akıllı telefon ve tabletlerde çalışacak mobil uygulamaların tamamı, UX (kullanıcı deneyimi) tasarımlarıdır.
                        </p>
                        <br />
                        <p className='text2'>
                            Kurumsal ve bireysel taleplerinize özel, mobil uygulama tasarımı ile, dijital varlığınızı güçlendirebilirsiniz. 
                            Mobil uygulama tasarımı çizimi öncesinde, tasarım şeması ve işleyiş belirlenerek taleplerinizi karşılayacak bir mobil uygulama geliştirilir.
                        </p>    
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={ios} alt="ios uygulama"  width='142px' height='142px'/>
                        <h2 className='baslik9'>İOS Uygulamaları</h2>
                        <p className='text2'>iOS uygulamaları ile iOS işletim sistemine tamamen uyumlu bir mobil uygulamaya sahip olabilirsiniz.  
                            iOS mobil uygulama, kullanıcı dostu ve modern bir yapıda tasarlanır. 
                            Projenizin ihtiyacı olan tüm çözümlerini barındırır. iOS mobil uygulama ile 1 milyar üzerinde Apple kullanıcısına ulaşabilirsiniz.
                        </p>
                        <br />
                        <p className="text2">Apple markette yer alan mobil uygulama, iPhone marka tüm akıllı telefon ve iPad modellerinde çalışabilir. 
                            Markanızın dijital görünürlüğünü üst seviyeye çıkaracak iOS uygulama, ihtiyaçlarınıza yönelik tasarlanır.
                        </p>
                        <br />
                        <p className="text2">iOS mobil uygulama tasarımı sonrasında, size raporlar sunulur. Taleplerinize yönelik tüm geliştirmeler 
                            yapıldıktan sonra, test ve son kontroller yapılır. Mobil uygulamanız, Apple markette yayına hazır olarak teslim edilir. 
                            iOS mobil uygulama ile Apple markalı cep telefonu ve tabletlerde hızlı performans sağlanır.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={android} alt="android uygulama"  width='142px' height='142px'/>
                        <h2 className='baslik9'>Android Uygulamaları</h2>
                        <p className='text2'>Android uygulamaları ile birçok akıllı telefon ve tablet kullanıcısına erişilebilir.  
                            Öncelikle projeden beklentileriniz dinlenir ve projenizin 
                            hedeflerine yönelik teknik ve stratejik planlama yapılır.
                        </p>
                        <br />
                        <p className="text2">Planlama süresi sonrasında, modern yazılım teknolojileri kullanılarak taleplerinize uygun bir 
                            Android mobil uygulama tasarlanır. 
                            Test, iyileştirme ve son kontroller sonrasında Android uygulama, yayına hazır halde teslim edilir.
                        </p>
                        <p className="text2">Android uygulamaları ile Google Play Store ve Huawei App Gallery üzerinden milyarlarca 
                            mobil kullanıcıya ulaşabilirsiniz. Android mobil uygulamalar, markanızın ihtiyaçlarına özel olarak tasarlanır. Uygulama projeleri, 
                            Android işletim sistemine sahip tüm akıllı telefon ve tabletlerde kullanıcı deneyimi dikkate alınarak tasarlanır.
                        </p>
                    </div>
                    <div className="icerik" >
                        <img className='icon' src={crossPlatform} alt="cross platform"  width='142px' height='142px' />
                        <h2 className='baslik9'>Çapraz Platform Uygulamalar</h2>
                        <p className='text2'>Çapraz platform uygulamalar ile Android ve iOS platformlarında çalışan tek bir mobil uygulamaya sahip olunabilir.  
                            React Native, Flutter ve diğer mobil teknolojiler ile farklı işletim sistemleriyle uyumlu mobil uygulama geliştirilebilir.
                        </p>
                        <br />
                        <p className="text2">
                            Markanız, çapraz platform mobil uygulamalar ile performans kaybı olmadan, düşük maliyetlerle, 
                            daha fazla kullanıcı ve takipçiye ulaşabilir. Çapraz platform uygulamalar sayesinde, pazara daha hızlı bir şekilde 
                            girebilirsiniz.


                        </p>
                    </div>
                    <div className="product">
                        <div className="productDetails" style={{zIndex:'0'}}>
                            <div>
                                <p className='text2'>İşinizi bizimle büyütün</p>
                            </div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <a className='contentBtn text' href="/teklif-al">TEKLİF AL <ion-icon name="arrow-forward-outline"></ion-icon></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default WebTasarim