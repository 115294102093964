import React from 'react'
import './Jobs.css'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import './Jobs.css'
// banners
import ozgungor from '../Assets/ozgungor.png'
import ramnak from '../Assets/ramnak.png'
import assembly from '../Assets/assembly.png'
import horoz from '../Assets/horoz.png'
import pcMockup from '../Assets/pcMockup.png'
import Vizyonsuz from '../Assets/pcMockups/vzmckp.png'
import Batutrans from '../Assets/pcMockups/batutrans-mckp.png'
import Immersive from '../Assets/pcMockups/immersive-mckp.png'
import Mobilya from '../Assets/pcMockups/mobilyadknm-mckp.png'
import OsmanThe from '../Assets/pcMockups/osmanthewho-mckp.png'
import Vinsa from '../Assets/pcMockups/winsa-mckp.png'

const Jobs = () => {
 // yazı rengi değiştirme
//  useLayoutEffect(() => {
//   let mm = gsap.matchMedia();
//   gsap.registerPlugin(ScrollTrigger);
//   mm.add('(min-width: 1100px)', () => {
//     const textElements = gsap.utils.toArray('.baslik5');

//     textElements.forEach((text) => {
//       gsap.to(text, {
//         backgroundSize: '60%',
//         ease: 'none',
//         scrollTrigger: {
//           trigger: text,
//           start: 'top 50%',
//           end: 'bottom',
//           // markers:true,
//           scrub: true,
//         },
//       });
//     });
//   });
//   return () => mm.revert(); 
// }, []);
    // yazı rengi değiştirme
    useGSAP(()=>{
      const tl = gsap.timeline();
  
      tl.from(
        ".jobs",
        {
          y:"20px",
          opacity:0,
          duration:1,
         
        }
      )
      
    
      })  
    
   
  return (
   <section data-color="white white " id='jobs' >
     
        <div className="container">
        <h2 className='baslik6'>Neler Yaptık  <strong style={{color:'#eb2f5b'}}>?</strong> </h2>
        
          <div className="jobs">
           
            <div className="jobs__card">
            <h2 className='cardBaslik' style={{color:'#a8a8a9'}}>İmmersive İmage</h2>
              <p className='text'>İmmersive İmage için (UX), kullanıcı deneyimine uygun, bir web sitesi tasarladık.</p>
              <img src={Immersive} alt=""  />
            </div>
            <div className="jobs__card">
            <h2 className='cardBaslik'  style={{color:'#D22424'}}>Mobilya Dükkanım</h2>
              <p className='text'>Mobilya Dükkanım (E-ticaret platformu), müşterilere üst düzey alışveriş deneyimi sunmak için özenle tasarlandı.</p>
              <img src={Mobilya} alt=""  />
            </div>
            
            <div className="jobs__card">
            <h2 className='cardBaslik' style={{color:'#000'}}>Osman The Who</h2>
              <p className='text'>Osman The Who için (UX)(UI), kullanıcı deneyimine uygun, yaratıcı bir web sitesi tasarladık.</p>
              <img src={OsmanThe} alt=""  />
            </div>
            <div className="jobs__card">
            <h2 className='cardBaslik' style={{color:'#00924f'}}>Vinsa(Yeşilyurt Bayii)</h2>
              <p className='text'>Vinsa(Yeşilyurt Bayii), UI ,UX  web tasarımı ve web yazılımı çalışmaları tamamlandı.</p>
              <img src={Vinsa} alt=""  />
            </div>
            <div className="jobs__card">
            <h2 className='cardBaslik' style={{color:'#0f9360'}}>Batutrans Lojistik</h2>
              <p className='text'>Batutrans Lojistik için (UX), kullanıcı deneyimine uygun, bir web sitesi tasarladık.</p>
              <img src={Batutrans} alt=""  />
            </div>
            <div className="jobs__card">
              <h2 className='cardBaslik'>Vizyonsuz Karavan</h2>
              <p className='text'>Vizyonsuz Karavan Kiralama için (UX), kullanıcı deneyimine uygun, bir web sitesi tasarladık.</p>
              <img src={Vizyonsuz} alt=""  />
            </div>
            
          </div>
          <div className='örn'>
            <a className='btnn ' href="#" role='button'>Tümünü Gör </a>

        </div>
        </div>
   </section>
    
  )
}

export default Jobs